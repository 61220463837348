import { useState, useEffect, useContext, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import { useQuery, useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import gql from "graphql-tag";
import { addMinutes, differenceInSeconds } from "date-fns";
import { isAuthed } from "../../utils/authorization";
import { DialogContext } from "../../context/dialogContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { MeetingContext } from "../../context/meetingContext";
import { FetchContext } from "../../context/fetchContext";

import { MEETING_FIELDS, TODO_FIELDS, USER_FIELDS, WEEKLY_TARGET_FIELDS } from "../../utils/fragments";
import { MEETINGS_SUBSCRIPTION } from "../../utils/subscriptions";

const useMeeting = (user, oneYearCorpPlan) => {
  const params = useParams();
  const history = useHistory();
  const { dialog, setDialog } = useContext(DialogContext);
  const { snack } = useContext(SnackbarContext);
  const { data: activeMeetingData, activeMeeting } = useContext(MeetingContext);

  const [inMeeting, setInMeeting] = useState(false);
  const [userIds, setUserIds] = useState(null);

  const { data, loading, subscribeToMore: subscribeToMoreMeeting } = useQuery(GET_MEETING, { variables: { id: params.meeting_id }, fetchPolicy: "network-only" });
  const { data: currentSessionSubscription, loading: subloading } = useSubscription(SUBSCRIBE_TO_CURRENT_SESSION, {
    variables: { id: params.meeting_id },
  });

  const { requestFetch } = useContext(FetchContext);
    // Use the useSubscription hook at the top level
  const { data: notesSubscription, loading: notesLoading } = useSubscription(SUBSCRIBE_TO_NOTES, {
    variables: { users: userIds },
    skip: userIds === null, // Skip the subscription if userIds is empty
  });

  const [getUserData, { data: userData, loading: userLoading }] = useLazyQuery(GET_DATA_FOR_USERS);
  const [updateMeeting] = useMutation(UPDATE_MEETING);
  const [updateCurrentSession] = useMutation(UPDATE_CURRENT_SESSION);
  const [exitMeeting] = useMutation(EXIT_MEETING);
  const [updateSuccessCriteria] = useMutation(UPDATE_SUCCESS_CRITERIA);
  const [completeMeeting] = useMutation(COMPLETE_MEETING);
  const [rateMeeting] = useMutation(RATE_MEETING);
  
  useEffect(() => { 
    if (notesLoading) {
      return;
    }
    requestFetch();
  }, [notesSubscription, notesLoading]);


  useEffect(() => {
    if (loading) {
      return;
    }

    const unsubscribe = subscribeToMoreMeeting({
      document: MEETINGS_SUBSCRIPTION,
      variables: { meetingUser: user.user.id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }        
        const meeting = subscriptionData.data.meetingPayload.meetingMutated;
        const action = subscriptionData.data.meetingPayload.action;

        let newMeeting;
        if (_.get(prev,'meeting.id',null) === meeting.id) {
          switch (action) {
            case "update":
              newMeeting = meeting;
              break;
            case "delete":
              newMeeting = null;
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            meeting: newMeeting,
          });
        }
      },
    });
    return () => {
      unsubscribe();
    };
  }, [loading, subscribeToMoreMeeting, user.user.id]);
  
  const isAdmin = isAuthed(user.user, "department admin") || user.user.id === _.get(data, "meeting.owner.id");
  const isTempAdmin = user.user.id === _.get(currentSessionSubscription, "meetingSub.session.temporaryAdmin");
  const canStartMeeting = (isAdmin || isTempAdmin);


  const filterOnSelected = (_.get(currentSessionSubscription, "meetingSub.session.filterOnSelected") || false);
  if (filterOnSelected !== activeMeetingData.filterOnSelected) {
    activeMeeting(activeMeetingData.meeting, filterOnSelected);
  } 
  
  useEffect(() => {
    if (_.get(data, "meeting",null)) {
      activeMeeting(_.get(data, 'meeting',null), activeMeetingData.filterOnSelected);

      const { owner, users } = data.meeting;
      const allInvitedUsers = [...users, owner];
      const userById = _.keyBy(allInvitedUsers, "id");
      const userIds = Object.keys(userById);
      setUserIds(userIds);

    }


  }, [data]);


  const handleChangeStep = (index) => async () => {
    //only meeting owner updates steps
    const nextStep = _.get(data, ["meeting", "steps", index.toString()]);
    let session = _.get(currentSessionSubscription, "meetingSub.session");
    const currentSessionCopy = _.cloneDeep(session);
    const currentCompletedStepIndex = _.get(currentSessionCopy, "completedStepIndex", null);

    // record time spent in previous step
    const prevStepIndex = _.get(currentSessionCopy, "currentStepIndex") || 0;
    const prevStepStartTime = _.get(currentSessionCopy, `stepTime.${prevStepIndex}.stepStartTime`);
    const prevStepTimeSpentInSeconds = _.get(currentSessionCopy, `stepTime.${prevStepIndex}.timeSpentInSeconds`, 0);
    const newTimeSpentInSeconds = prevStepTimeSpentInSeconds + differenceInSeconds(new Date(), new Date(prevStepStartTime));

    _.set(currentSessionCopy, `stepTime.${prevStepIndex}.timeSpentInSeconds`, newTimeSpentInSeconds);
    _.set(currentSessionCopy, `stepTime.${prevStepIndex}.stepStartTime`, null);
    _.set(currentSessionCopy, `stepTime.${index}.stepStartTime`, new Date());

    const currentSession = {
      ...currentSessionCopy,
      currentStepIndex: index,
      currentStepEndTime: addMinutes(new Date(), nextStep.duration),
      completedStepIndex: index >= _.get(currentSessionCopy, "completedStepIndex", 0) ? index : currentCompletedStepIndex,
      currentLeader: _.get(user,'user.id',null),
      stepFilters: null,
    };

    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession } });
  };

  const handleStepButton = (inc) => () => {
    const indexOfCurr = currentSessionSubscription.meetingSub.session.currentStepIndex;
    handleChangeStep(indexOfCurr + inc)();
  };

  const handleToggleFilterOnSelected = async (e) => {
    let session = _.get(currentSessionSubscription, "meetingSub.session");
    const currentSessionCopy = _.cloneDeep(session);
    
    const filterOnSelected = _.get(currentSessionCopy, "filterOnSelected") || false;
    
    const currentSession = {
      ...currentSessionCopy,
      filterOnSelected: !filterOnSelected,
    };

    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession } });    

  }  

  const debouncedUpdateSession = useMemo(
    () =>
      _.debounce(async (stepFilters) => {
        let session = _.get(currentSessionSubscription, "meetingSub.session");
        const currentSessionCopy = _.cloneDeep(session);

        const currentSession = {
          ...currentSessionCopy,
          stepFilters: stepFilters,
        };

        // Perform the async update
        await updateCurrentSession({
          variables: { id: params.meeting_id, currentSession },
        });
      }, 500), // Adjust debounce delay as needed
    [currentSessionSubscription, updateCurrentSession, params.meeting_id] // Ensure dependencies are updated properly
  );

  // Cleanup the debounced function on unmount
  useEffect(() => {
    return () => debouncedUpdateSession.cancel();
  }, [debouncedUpdateSession]);

  const handleSetStepFilters = async (stepFilters) => {
    debouncedUpdateSession(stepFilters); 
  }  

  // const handleAddDialog =
  //   (category, referenceModel = null, referenceId = null) =>
  //   () => {
  //     setDialog({ ...dialog, addTodoDialog: { open: true, category, referenceId, referenceModel } });
  //   };

  const handleAddScDialog = (id) => () => {
    setDialog({ ...dialog, addSuccessCriteriaDialog: id });
  };

  const handleResetSession = async () => {
    let session = _.get(currentSessionSubscription, "meetingSub.session", {});
    // if session is killed due to server restart, revive the timer
    if ( _.isNil(_.get(data, "meeting.endTime"))) {
      session.sessionStartTime = new Date();
      session.currentStepIndex =  0;
      session.currentStepEndTime = addMinutes(new Date(), data.meeting.steps[0].duration);
      session.completedStepIndex = null;
      session.currentRock = null;
      session.stepFilters = null;
      session.currentLeader = _.get(user,'user.id',null);
      session.stepTime= data.meeting.steps.map((step, idx) => {
        return { stepStartTime: idx === 0 ? new Date() : null, timeSpentInSeconds: 0 };
      });      
    }

    await updateMeeting({ variables: { id: params.meeting_id, status: "in progress" } });
    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession: session } });    
  }

  const handleEnterMeeting = async () => {
    let session = _.get(currentSessionSubscription, "meetingSub.session");

    let currentSessionCopy = null;
    const currentStepIndex = _.get(session, "currentStepIndex", null);
    if (_.isNil(currentStepIndex)) {
      currentSessionCopy = {
        sessionStartTime: new Date(),
        currentStepIndex: 0,
        currentStepEndTime: addMinutes(new Date(), data.meeting.steps[0].duration),
        completedStepIndex: null,
        currentRock: null,
        stepFilters: null,
        currentLeader: _.get(user,'user.id',null),
        users: null,
        stepTime: data.meeting.steps.map((step, idx) => {
          return { stepStartTime: idx === 0 ? new Date() : null, timeSpentInSeconds: 0 };
        }),
      };
    } 
    else {
      currentSessionCopy = _.cloneDeep(session);
    }

    if (!(currentSessionCopy.users || []).includes(user.user.id)) {
      const newUsers = [...(currentSessionCopy.users || []), user.user.id];
      _.set(currentSessionCopy, "users", newUsers);
    }

    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession: currentSessionCopy } });

  };

  const handleJoinStartedMeeting = async () => {
    let session = _.get(currentSessionSubscription, "meetingSub.session", {});
    const status = _.get(data, "meeting.status");
    // if session is killed due to server restart, revive the timer
    if (status === "in progress") {
      if (_.isNil(session.sessionStartTime) && _.isNil(_.get(data, "meeting.endTime"))) {
        session.sessionStartTime = new Date();
      }
      if (_.isNil(session.stepTime)) {
        session.stepTime = data.meeting.steps.map((step, idx) => {
          return { stepStartTime: idx === 0 ? new Date() : null, timeSpentInSeconds: 0 };
        });
      }
    }

    //Note: this is triggered only when facilitator clicks start meeting (not during lobby)
    setInMeeting(true);
    activeMeeting(_.get(data, 'meeting',null), activeMeetingData.filterOnSelected);

    const currentSessionCopy = _.cloneDeep(session);
    if ((currentSessionCopy.users || []).includes(user.user.id)) {
      return;
    }

    const newUsers = [...(currentSessionCopy.users || []), user.user.id];
    _.set(currentSessionCopy, "users", newUsers);
    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession: currentSessionCopy } });
  };


  const handleExitMeeting = async () => {
    console.log("handleExitMeeting triggered");
    handleConfirmOpen(false);
    activeMeeting(null, false);
    await exitMeeting({ variables: { id: params.meeting_id, userId: user.user.id } });
  };

  const handlePassAdminControl = async (userId) => {
    let session = _.get(currentSessionSubscription, "meetingSub.session", {});

    const currentSessionCopy = _.omit(_.cloneDeep(session), ["__typename"]);

    _.set(currentSessionCopy, "temporaryAdmin", userId);
    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession: currentSessionCopy } });
  };

  const handleRevokeAdminControl = async () => {
    let session = _.get(currentSessionSubscription, "meetingSub.session", {});

    const currentSessionCopy = _.omit(_.cloneDeep(session), ["__typename"]);

    _.set(currentSessionCopy, "temporaryAdmin", null);
    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession: currentSessionCopy } });
  };

  useEffect(() => {
    // closing browser
    window.addEventListener("beforeunload", handleTabClosing);
    // switch url (doesn't seem to work)
    window.addEventListener("popstate", handleTabClosing);
    // lose internet connection
    window.addEventListener("offline", handleTabClosing);
    // regain internet connection
    window.addEventListener("online", handleJoinMeeting);
    return () => {
      window.removeEventListener("beforeunload", handleTabClosing);
      window.removeEventListener("popstate", handleTabClosing);
      window.removeEventListener("offline", handleTabClosing);
      window.removeEventListener("online", handleJoinMeeting);
    };
  });

  const handleTabClosing = () => {
    console.log("handleTabClosing triggered");
    handleExitMeeting();
  };

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleConfirmOpen = (open) => () => {
    setConfirmOpen(open);
  };

  const handleFinishMeeting = async () => {

    console.log("handleFinishMeeting triggered 2");
    setConfirmOpen(false);

    const { issues, rocks, todos, weeklyTargets } = userData;
    const issueIds = (issues || []).map((todo) => todo.id),
      todoIds = (todos || []).map((todo) => todo.id),
      rockIds = (rocks || []).map((rock) => rock.id),
      weeklyTargetIds = (weeklyTargets || []).map((wt) => wt.id);

    await completeMeeting({
      variables: { id: params.meeting_id, issues: issueIds, todos: todoIds, rocks: rockIds, weeklyTargets: weeklyTargetIds },
    });
  };

  const handleUpdateSuccessCriteria = (id, done) => {
    updateSuccessCriteria({ variables: { id, done } });
  };

  const handleRateMeeting = (userId, value, comment) => {
    // Convert rating to a number if necessary, or set to null if it's blank
    const parsedValue = value === "" ? null : Number(value);

    rateMeeting({ variables: { id: params.meeting_id, user: userId, value: parsedValue, comment } });
  }

  const verifyUsers = (meeting, userId) => {
    return _.some(meeting.users, ["id", userId]) || meeting.owner.id === userId;
  };

  /*
  useEffect(() => {
    // Data relavent to the meeting is added
    if (data) {
      const { status, steps, users, owner, plan, sharedPlanId } = data.meeting;

      getOneYearPlans({ variables: { organization: params.org, oneYearCorpPlan: _.get(plan,"id",null) } });
      //const userIds = users.map(({ id }) => id).concat([owner.id]);
      //getUserData({ variables: { organization: params.org, users: userIds } });
    }
  }, [data]);

  useEffect(() => {
    // Data relavent to the meeting is added
    if (data) {
      const { users, owner, sharedPlanId } = data.meeting;
      const userIds = users.map(({ id }) => id).concat([owner.id]);
      if (oneYearPlans) {
        const selectedPlanId = _.get(oneYearPlans,"plans",[]).find(plan => plan.sharedPlanId === sharedPlanId);
        getUserData({ variables: { organization: params.org, users: userIds, plan: _.get(selectedPlanId,"id",null) } });
      }
      else {
        getUserData({ variables: { organization: params.org, users: userIds } });
      }      
    }
  }, [oneYearPlans]);*/

  useEffect(() => {
    // Data relavent to the meeting is added
    if (data) {
      const { users, owner, sharedPlanId } = data.meeting;
      const userIds = users.map(({ id }) => id).concat([owner.id]);
      if (oneYearCorpPlan) {
        getUserData({ variables: { organization: params.org, users: userIds, sharedPlanId, oneYearCorpPlan } });      
      }
      else {
        getUserData({ variables: { organization: params.org, users: userIds } });
       }       
    }
  }, [oneYearCorpPlan, data]);

  useEffect(() => {
    return () => {
      // switch url exit user session
      handleTabClosing();
    };
  }, []);
  
  const handleJoinMeeting = () => {
    if (data && !inMeeting && !subloading) {
      if (!verifyUsers(data.meeting, user.user.id)) {
        history.push(`/${params.org}`);
        snack("You need permission to access the meeting", "info");
        return;
      }

      if (_.get(data, "meeting.status") === "scheduled") {
        handleEnterMeeting();
      } else {
        handleJoinStartedMeeting();
      }
    }
  }

  useEffect(() => {
    if (data && !inMeeting && !subloading) {
      handleJoinMeeting();
    }
  }, [subloading, data]);



  const memoizedData = useMemo(() => data, [data]);

  const memoizedCurrentStepIndex = useMemo(
    () => _.get(currentSessionSubscription, "meetingSub.session.currentStepIndex") || 0,
    [currentSessionSubscription]
  );

  const memoizedCompletedStepIndex = useMemo(
    () => _.get(currentSessionSubscription, "meetingSub.session.completedStepIndex", null),
    [currentSessionSubscription]
  );

  const memoizedFilterOnSelected = useMemo(
    () => _.get(currentSessionSubscription, "meetingSub.session.filterOnSelected") || false,
    [currentSessionSubscription]
  );

  const memoizedStepFilters = useMemo(
    () => _.get(currentSessionSubscription, "meetingSub.session.stepFilters") || null,
    [currentSessionSubscription]
  );

  const memoizedCurrentLeader = useMemo(
    () => _.get(currentSessionSubscription, "meetingSub.session.currentLeader") || null,
    [currentSessionSubscription]
  );

  return {
    loading,
    inMeeting,
    canStartMeeting,
    userLoading,
    subloading,
    isAdmin,
    isTempAdmin,
    data: memoizedData,
    userData,
    currentStepIndex: memoizedCurrentStepIndex,
    completedStepIndex: memoizedCompletedStepIndex,
    currentSessionSubscription,
    snack,
    confirmOpen,
    filterOnSelected: memoizedFilterOnSelected,
    stepFilters: memoizedStepFilters,
    currentLeader: memoizedCurrentLeader,
    handleChangeStep,
    handleStepButton,
    handleAddScDialog,
    handleResetSession,
    handleUpdateSuccessCriteria,
    handleRateMeeting,
    handleFinishMeeting,
    handleExitMeeting,
    handlePassAdminControl,
    handleRevokeAdminControl,
    handleConfirmOpen,
    handleToggleFilterOnSelected,
    handleSetStepFilters,
  };
};

export default useMeeting;

const GET_MEETING = gql`
  ${MEETING_FIELDS}
  query UseMeeting_GetMeeting($id: ID!) {
    meeting(id: $id) {
      ...MeetingFields
    }
  }
`;

const SUBSCRIBE_TO_CURRENT_SESSION = gql`
  subscription ($id: ID!) {
    meetingSub(id: $id) {
      session {
        sessionStartTime
        currentStepIndex
        currentStepEndTime
        completedStepIndex
        filterOnSelected
        stepFilters {
          page
          rowsPerPage
          showCompleted
          sortValue
          sortOrder
          searchTerm
          selectedQuarter
          showAllSc
          showHiddenRocks
          showHidden
        }
        currentLeader
        currentRock
        users
        temporaryAdmin
        sessionEndTime
        stepTime {
          stepStartTime
          timeSpentInSeconds
        }
      }
      meetingId
    }
  }
`;

const GET_DATA_FOR_USERS = gql`
  query UseMeeting_GetDataForUsers($organization: ID!, $users: [ID!], $sharedPlanId: ID, $oneYearCorpPlan: ID) {
    organization(id: $organization) {
      id
      fiscalYear
    }
    weeklyTargets(organization: $organization, sharedPlanId: $sharedPlanId, oneYearCorpPlan: $oneYearCorpPlan) {
      ...WeeklyTargetFields
    }

    rocks(organization: $organization, users: $users, sharedPlanId: $sharedPlanId, oneYearCorpPlan: $oneYearCorpPlan) {
      id
      value
      index
      status
      notes {
        id
        user {
          name {
            first
            last
          }
        }
        text
        url
        filename
        type
        date
      }
      objective {
        id
        value
      }
      users {
        ...UserFields
      }
      successCriterias {
        id
        value
        done
      }
      plan {
        id
        departmentName
        shortName
        color
        year
        plan {
          id
          year
        }
      }
      todos {
        id: _id
        createdAt
      }
    }

    todos(organization: $organization, users: $users, category: "todo", sharedPlanId: $sharedPlanId, oneYearCorpPlan: $oneYearCorpPlan) {
      ...TodoFields
    }

    issues: todos(organization: $organization, users: $users, category: "issue", sharedPlanId: $sharedPlanId, oneYearCorpPlan: $oneYearCorpPlan) {
      ...TodoFields
    }
  }
  ${WEEKLY_TARGET_FIELDS}
  ${USER_FIELDS}
  ${TODO_FIELDS}
`;

const UPDATE_MEETING = gql`
  ${MEETING_FIELDS}
  mutation UseMeeting_UpdateMeeting($id: ID!, $status: String!) {
    updateMeeting(id: $id, status: $status) {
      ...MeetingFields
    }
  }
`;

const UPDATE_CURRENT_SESSION = gql`
  mutation UseMeeting_UpdateCurrSession($id: ID!, $currentSession: InputCurrentSession!) {
    updateCurrentSession(id: $id, currentSession: $currentSession)
  }
`;

const EXIT_MEETING = gql`
  mutation UseMeeting_ExitMeeting($id: ID!, $userId: ID!) {
    exitMeeting(id: $id, userId: $userId)
  }
`;

const UPDATE_SUCCESS_CRITERIA = gql`
  mutation UseMeeting_UpdateSc($id: ID!, $done: Boolean!) {
    updateSuccessCriteria(id: $id, done: $done)
  }
`;

 const RATE_MEETING = gql`
   mutation UseMeeting_RateMeeting($id: ID!, $user: ID!, $value: Float, $comment: String) {
     rateMeeting(id: $id, user: $user, value: $value, comment: $comment)
   }
 `;

const COMPLETE_MEETING = gql`
  mutation UseMeeting_CompleteMeeting($id: ID!, $weeklyTargets: [ID!], $todos: [ID!], $issues: [ID!], $rocks: [ID!]) {
    completeMeeting(id: $id, weeklyTargets: $weeklyTargets, todos: $todos, issues: $issues, rocks: $rocks)
  }
`;

const SUBSCRIBE_TO_NOTES = gql`
  subscription ($users: [ID!]) {
    notePayload(users: $users) {
      noteMutated {
        id
        user {
          id
          name {
            first
            last
          }
          profilePicture
        }
        organization
        date
        text
        url
        filename
        type
        core
      }
      action
    }
  }
`;