import React, { useEffect, useState } from "react";
import styles from "./MeetingStepContent.module.scss";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import RichText from "../RichText/RichText";
import { deserialize, serialize } from "../RichText/functions";
import SelectStepContentItems from "./SelectStepContentItems";
import { getReferenceModelFromType } from "../../utils/misc";

import {
  TextField,
  FormControl,
  Tooltip,
} from "@material-ui/core";

import { Icon as MdiIcon } from "@mdi/react";

import {
  mdiRhombus,
  mdiRhombusSplit,
  mdiPoll,
  mdiText,
  mdiLightbulbQuestion,
  mdiBullseyeArrow,
  mdiAlertDecagram,
  mdiCheckboxMarked,
  mdiChartTimelineVariant,
} from "@mdi/js";

const initErrorForm = {
  type: ["required"],
};

const StepContentItemEditorForm = ({ contentItem, lockContentTypes, stepDescription, org, planId, sharedPlanId, multiple = true, onLoadingChange, onFormChange  }) => {

    const [loading, setLoading] = useState(false);
   
    const type = _.get(contentItem, "type", _.get(lockContentTypes,0,"prompt"));
    const label = _.get(contentItem, "label", null);
    const text = _.get(contentItem, "text", "");
    const referenceIds = _.get(contentItem, "referenceIds", []);  
    
    const [lastSelectedIdsByType, setLastSelectedIdsByType] = useState({[type]: referenceIds});  
  
    const initForm = {
      type,
      label,
      text,
      referenceIds: referenceIds,
    };
  
  
    const { form, formErrors, handleChange, handleChangeManual, handleChangeToggleButton, resetForm, validateForm } = useForm({
      initForm,
      initErrorForm,
    });
  
    const referenceModel = getReferenceModelFromType(form.type);
  
    const handleChangeReferenceIds = (referenceIDs) => {
      handleChangeManual({ name: "referenceIds", value: referenceIDs });
    }
  
    const handleFormChange = () => {
      const isValid = validateForm();
      onFormChange({ isValid: isValid, form: form });
    };
  
    useEffect(() => {
      handleFormChange();
    }, [form]);
   
    useEffect(() => {
      if (form.type) {
        let newReferenceIds = _.cloneDeep(_.get(lastSelectedIdsByType, [form.type], []));
        handleChangeManual({ name: "referenceIds", value: newReferenceIds });
      }
    }, [form.type]);
  
    const handleRichTextChange = (e) => {
      const { name } = e.target;
      if (name === "text") {
        const serializedText = serialize(e.target.value);
        if (!_.isEqual(form.text, serializedText)) {
          handleChangeManual({ name: "text", value: serializedText });
        }
      } 
    };
  
    const toggleButtons = [
      { value: 'textBlock', title: 'Custom Text', icon: mdiText },
      { value: 'prompt', title: 'Prompt or Question', icon: mdiLightbulbQuestion },
      { value: 'successCriteriaReview', title: 'Success Criteria Review', icon: mdiRhombusSplit },
      { value: 'rockReview', title: 'Rocks Review', icon: mdiRhombus },
      { value: 'objectiveReview', title: 'Objectives Review', icon: mdiChartTimelineVariant },
      { value: 'metricReview', title: 'Metrics Review', icon: mdiPoll },
      { value: 'weeklyTargetReview', title: 'KPIs Review', icon: mdiBullseyeArrow },
      { value: 'issueReview', title: 'Issues Review', icon: mdiAlertDecagram },
      { value: 'todoReview', title: 'Todos Review', icon: mdiCheckboxMarked },
    ];
  
    return (
      <>
        <div style={{ display: lockContentTypes.length > 1 ? 'block' : 'none' }} className={styles.modelButtons}>
        <ToggleButtonGroup
          exclusive
          value={form.type}
          onChange={(e, value) => {
            setLastSelectedIdsByType((prev) => ({
              ...prev,
              [form.type]: form.referenceIds,
            }));              
            handleChangeToggleButton("type")(e, value);
          }}
          className={type ? undefined : styles.marginBottom}
        >
          {toggleButtons
            .filter((button) => lockContentTypes.includes(button.value))
            .map((button) => (
              <ToggleButton key={button.value} value={button.value}>
                <Tooltip title={button.title}>
                  <MdiIcon path={button.icon} size={1.25} color="rgba(0, 0, 0, 0.54)" />
                </Tooltip>
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      </div>
  
      {(_.isNull(referenceModel) || referenceModel === 'SystemPrompt') && (
      <TextField
        autoFocus
        label={`Header/Description (Optional)`}
        name="label"
        fullWidth
        variant="outlined"
        margin="normal"
        multiline
        value={form.label || ""}
        onChange={handleChange}
        helperText={formErrors.label}
        error={Boolean(formErrors.label)}
      />
      )}
  
      {referenceModel && (
        <SelectStepContentItems 
          type={form.type}
          referenceIds={form.referenceIds}
            stepDescription={stepDescription}
            handleChange={handleChangeReferenceIds}
            error={formErrors.referenceIds}
            org={org}
            planId={planId}
            sharedPlanId={sharedPlanId}
            onLoadingChange={onLoadingChange}
            multiple={multiple}
          />
        )}
  
        {(_.isNull(referenceModel) ) && (
        <FormControl fullWidth variant="outlined" margin="normal" error={formErrors.text} className={styles.msContainer}>
          <RichText value={deserialize(form.text)} readOnly={false} onChange={(value) => handleRichTextChange({ target: { name: "text", value } })} />
          {/*<div style={{ marginTop: 16 }}>
            <label htmlFor="file-upload" style={{ display: "inline-block" }}>
              <Button color="primary" variant="contained" component="span">
                Upload File
              </Button>
            </label>
          {/* <input
              name="file"
              onChange={handleChange}
              accept="image/*, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"
              id="file-upload"
              type="file"
              className={styles.input}
            />
            <Typography variant="subtitle2" display="inline" className={styles.filename}>
              {_.get(editMode, "file.name", _.get(editMode, "filename"))}
            </Typography>
          </div>    */}
        </FormControl>
        )}  
    </>
    );
  }

  export default StepContentItemEditorForm;