import React, { useState, useContext, useEffect } from "react";
import styles from "./SystemPrompts.module.scss";
import _ from "lodash";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Card,
  CardContent,
  CardActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Hidden,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { UserContext } from "../../context/userContext";
import CardTitle from "../../components/CardTitle/CardTitle";
import { useForm } from "../../hooks";
import Loading from "../../components/Loading/Loading";
import SystemPrompt from "./SystemPrompt";
import EditDialog from "./EditDialog";
import useTableFilters from "../../hooks/useTableFilters";

import { USER_FIELDS } from "../../utils/fragments";
import { SYSTEMPROMPT_CATEGORIES } from "../../utils/const";

const SystemPrompts = ({ params, org }) => {
  // const url = `${process.env.REACT_APP_URL}/signup?org=${params.org}`;
  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { user } = useContext(UserContext);

  // dialog
  const [open, setOpen] = useState(false);
  const [selectedSystemPrompt, setSelectedSystemPrompt] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // pagination
  const { page, rowsPerPage, searchTerm, debouncedSearchTerm, handleChangePage, handleRowsPerPage, setSearchTerm } = useTableFilters({
    initialValue: {},
  });
  const [getSystemPrompts, { data, loading, refetch }] = useLazyQuery(GET_SYSTEMPROMPTS);
  const [createSystemPrompt] = useMutation(CREATE_SYSTEMPROMPT);
  const [updateSystemPrompt] = useMutation(UPDATE_SYSTEMPROMPT);
  const [deleteSystemPrompt] = useMutation(DELETE_SYSTEMPROMPT);

  const defaultType = Object.values(SYSTEMPROMPT_CATEGORIES).find(category => category.default)?.value || SYSTEMPROMPT_CATEGORIES['icebreaker'].value;

  const initForm = {
    organization: params.org,
    category: defaultType,
    value: null,
    summary: "",
    author: user.user.id,
  };
  const initErrorForm = {
    value: ["required"],
    category: ["required"],
  };

  const { form, formErrors, handleChange, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleDialog =
    (open, systemprompt = null) =>
      () => {
        setSelectedSystemPrompt(systemprompt);
        setOpen(open);
      };

  const handleCreateSubmit = async (e) => {
    if (!validateForm()) return;
    e.preventDefault();


    const { organization, category, value, summary, author } = form;

    const { data } = await createSystemPrompt({
      variables: { organization, category, value, summary, author },
    });

    if (data.createSystemPrompt) {
      snack("Created new system prompt");
      requestFetch();
      resetForm();
      setOpen(false);
    } else {
      snack("Could not create the new system prompt", "error");
    }

  };

  const handleEditSubmit = async (e) => {
    if (!validateForm()) return;
    e.preventDefault();

    const { id, organization, category, value, summary, author } = form;
    const ok = await updateSystemPrompt({
      variables: { id, organization, category, value, summary, author },
    });

    if (ok) {
      snack(`Updated System Prompt: ${value}`);
      requestFetch();
      setOpen(false);
    }
  };


  const handleCreateCancel = (form) => {
    setSelectedSystemPrompt(null);
    setOpen(false);
  };

  const handleDelete = async (systemprompt) => {
    const { id, value } = systemprompt;
    const ok = await deleteSystemPrompt({ variables: { id } });

    if (ok) {
      snack(`Deleted prompt: ${value} ${value}`);
      requestFetch();
      setDeleteDialogOpen(false);
      setSelectedSystemPrompt(null);
    }
  };

  useEffect(() => {
    getSystemPrompts({ variables: { organization: params.org, category: null, offset: page, size: rowsPerPage, search: debouncedSearchTerm } });
  }, [page, rowsPerPage, debouncedSearchTerm]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [fetch]);

  if (loading || !data) return <Loading />;
  const systemPrompts = _.get(data, "systemPrompts", []);
  const count = !_.isNil(systemPrompts) ? systemPrompts.length : 0;

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Hidden xsDown>
            <Grid item xs={12}>
              <Typography variant="h6" className={styles.label}>
                Manage Questions & Prompts
              </Typography>
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <Card className={styles.card}>
              <CardTitle vertical color="teal">
                <Typography variant="h5" className={styles.title}>
                  Prompts
                </Typography>
              </CardTitle>
              <CardActions className={styles.cardActions}>
                <TextField
                  variant="outlined"
                  label="Search"
                  name="search"
                  value={searchTerm || ""}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  // onKeyPress={(e) => {
                  //   if (e.key === "Enter") handleSearch();
                  // }}
                  className={styles.left}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled={true}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  startIcon={<AddIcon />}
                  className={styles.leftIcon}
                  onClick={handleDialog(true)}
                  variant="contained"
                  color="primary"
                  data-test='button-new-prompt'
                >
                  New Prompt
                </Button>
              </CardActions>
              <CardContent className={styles.cardContent}>
                <Table size="small" className={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={styles.firstCell}>Category</TableCell>
                      <TableCell>Prompt</TableCell>
                      <TableCell>Organization</TableCell>
                      <TableCell>Summary</TableCell>
                      <TableCell>Author</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {systemPrompts &&
                      systemPrompts.map((systemPrompt) => {
                        return (
                          <SystemPrompt
                            key={systemPrompt.id}
                            systemprompt={systemPrompt}
                            snack={snack}
                            handleEdit={handleDialog(true, systemPrompt)}
                            handleDelete={() => {
                              setDeleteDialogOpen(true);
                              setSelectedSystemPrompt(systemPrompt);
                            }}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
                <TablePagination
                  page={page}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleRowsPerPage}
                  component="div"
                />
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </Container>

      <EditDialog
        open={open}
        handleClose={handleDialog(false)}
        systemprompt={selectedSystemPrompt}
        form={form}
        resetForm={resetForm}
        formErrors={formErrors}
        handleChange={handleChange}
        handleSubmit={selectedSystemPrompt ? handleEditSubmit : handleCreateSubmit}
        handleCreateCancel={handleCreateCancel}
      />

      {/* Delete Dialog */}
      {selectedSystemPrompt && (
        <Dialog
          open={deleteDialogOpen}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setDeleteDialogOpen(false);
            }
          }}
        >
          <DialogTitle>Delete System Prompt</DialogTitle>
          <DialogContent>
            Are you sure you want to delete they following prompt?<br /><ul><li>{selectedSystemPrompt.value}</li></ul>?
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                setDeleteDialogOpen(false);
                setSelectedSystemPrompt(null);
              }}
            >
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleDelete(selectedSystemPrompt)} variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default SystemPrompts;

const GET_SYSTEMPROMPTS = gql`
  ${USER_FIELDS}

  query SystemPrompts_GetSystemPrompts($organization: ID) {
    systemPrompts(organization: $organization) {
      id
      organization
      category
      value
      summary
      author {
        ...UserFields
      }
    }
  }
`;

const CREATE_SYSTEMPROMPT = gql`
  mutation SystemPrompts_CreateSystemPrompt(
    $organization: ID
    $category: String!
    $value: String!
    $summary: String!
    $author: ID
  ) {
    createSystemPrompt(
      organization: $organization
      category: $category
      value: $value
      summary: $summary
      author: $author
    ) 
  }
`;

const UPDATE_SYSTEMPROMPT = gql`
  mutation (
    $id: ID!
    $organization: ID
    $category: String
    $value: String
    $summary: String
  ) {
    updateSystemPrompt(
      id: $id
      organization: $organization
      category: $category
      value: $value
      summary: $summary
    )
  }
`;

const DELETE_SYSTEMPROMPT = gql`
  mutation ($id: ID!) {
    deleteSystemPrompt(id: $id)
  }
`;

