import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import gql from "graphql-tag";
import _ from "lodash";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URI,
  credentials: "same-origin",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
// Connect to the Apollo Server back end
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const getUser = (id) => {
  return client.query({
    query: gql`
    query GetUser{
      user(id: "${id}") {
        id
        name {
          first
          last
        }
        email
        auth
        plan{
          id
          departmentName
          closed
          sharedPlanId
        }
        profilePicture
        position
        organization
        organizations
        notificationSettings {
          weeklyReminder {
            frequency
            enabled
          }
          meetingReminder {
            frequency
            enabled
          }
        }
      }
    }
    `,
    fetchPolicy: "no-cache",
  });
};

export const getOneYearObjectives = ({ organization, ids }) => {
  return client.query({
    query: gql`
      {
        objectives(organization: "${organization}" ids: "${ids}") {
          id
          value
        }
      }
    `,
  });
};

export const getNotes = (id, model) => {
  return client.query({
    query: gql`
      query GetNotes{
        ${model}(id: "${id}") {
          notes {
            id
            user {
              id
              name {
                first
                last
              }
            }
            date
            text
            url
            filename
            type
          }
        }
      }
    `,
    fetchPolicy: "no-cache",
  });
};

export const getSubArrayFieldNotes = (id, model, field) => {
  return client.query({
    query: gql`
      query GetSubArrayFieldNotes{
        ${model}(id: "${id}") {
          ${field}{
            notes {
            id
            user {
              id
              name {
                first
                last
              }
            }
            date
            text
            url
            filename
            type
          }
          }
        }
      }
    `,
    fetchPolicy: "no-cache",
  });
};

export const getNestedNotes = (id, model, core) => {
  return client.query({
    query: gql`
      query GetNestedNotes{
        ${model}(id: "${id}" ){
          notes{
            ${core}{
              id
              user{
                id
                name{
                  first
                  last
                }
              }
              date 
              text
              url
              filename
              type
            }
          }
        }
      }
    `,
    fetchPolicy: "no-cache",
  });
};

export const getRockStatus = (id) => {
  return client.query({
    query: gql`
    query{
      rockStatus(id: "${id}")
    }
    `,
    fetchPolicy: "no-cache",
  });
};

export const getTodoTieIn = (id, model) => {
  return client.query({
    query: gql`
    query{
      ${_.camelCase(model)}(id: "${id}"){
        value
      }
    }
    `,
    fetchPolicy: "no-cache",
  });
};
