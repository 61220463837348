import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useContext } from "react";
import { WEEKLY_TARGET_FIELDS } from "../../../utils/fragments";
import WeeklyTargets from "../../../components/WeeklyTargets/WeeklyTargets";
import Loading from "../../../components/Loading/Loading";
import useCorpPlan from "../../../hooks/useCorpPlan";
import _ from "lodash";
import { FetchContext } from "../../../context/fetchContext";

const ScorecardStep = ({ step, userIds, org, canEdit, corpPlanId, sharedPlanId, filterOnSelected, handleSetStepFilters, stepFilters, isLeading }) => {

  const { fetch } = useContext(FetchContext);

  const variables = { users: userIds, organization: org.id, sharedPlanId: sharedPlanId, oneYearCorpPlan: corpPlanId };
  const { subscribeToMore, refetch, data, loading } = useQuery(SCORECARD_STEP_QUERY, { variables });


  const { corpForSelectedYear } = useCorpPlan({ fiscalYear: org.fiscalYear, orgId: org.id });

  const content = _.get(step, ["content",0,"referenceIds"], []).map((refId) => refId.id);
  const weeklyTargets = filterOnSelected ? _.get(data,"weeklyTargets",[]).filter((weeklyTargets) => content.includes(weeklyTargets.id)) : _.get(data,"weeklyTargets",[]);

  const location = 'scorecards';
  const storageValue = JSON.parse(sessionStorage.getItem(`${location}.showHiddenKpis`));
  const showHidden = _.isNil(storageValue) ? false : storageValue;

  const initialFilters =  {
    showHidden: showHidden,
    searchTerm: '',
  };
  
  useEffect(() => {
    refetch();
  }, [fetch]);

  useEffect(() => {
    if (isLeading && _.isNil(stepFilters)) {
        handleSetStepFilters(initialFilters);
    }
  }, [stepFilters]);

  useEffect(
    () => {

      if (loading) {
        return;
      }

      const unsubscribe = subscribeToMore({
        document: SCORECARD_STEP_SUBSCRIPTION,
        variables: { users: userIds },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const kpi = subscriptionData.data.weeklyTargetPayload.weeklyTargetMutated;
          const action = subscriptionData.data.weeklyTargetPayload.action;

          // Initialize prev.weeklyTargets if it's null, undefined, or empty
          const currentWeeklyTargets = prev?.weeklyTargets || [];

          let newWeeklyTargets;
          switch (action) {
            case "update":
              newWeeklyTargets = currentWeeklyTargets.map((weeklyTarget) => (weeklyTarget.id === kpi.id ? kpi : weeklyTarget));
              break;
            case "delete":
              newWeeklyTargets = currentWeeklyTargets.filter((weeklyTarget) => weeklyTarget.id !== kpi.id);
              break;
            case "create":
              const exists = currentWeeklyTargets.some((t) => t.id === kpi.id);
              newWeeklyTargets = exists ? currentWeeklyTargets : [...currentWeeklyTargets, kpi];              
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            weeklyTargets: newWeeklyTargets,
          });
        },
      });
      return () => {
        unsubscribe();
      };
    },
    [loading]
  );

  if (loading) return <Loading />;

  return (
    <div>
      <WeeklyTargets
        weeklyTargets={weeklyTargets}
        planId={_.get(corpForSelectedYear, "id")}
        plansOrder={org.plansOrder}
        variables={variables}
        range={5}
        canEdit={canEdit}
        handleSetStepFilters={handleSetStepFilters}
        stepFilters={stepFilters}
        isLeading={isLeading}
      />
    </div>
  );
};

export default ScorecardStep;

const SCORECARD_STEP_QUERY = gql`
  ${WEEKLY_TARGET_FIELDS}
  query ScorecardStepQuery($organization: ID!, $users: [ID!], $sharedPlanId: ID, $oneYearCorpPlan: ID) {
    weeklyTargets(organization: $organization, users: $users, sharedPlanId: $sharedPlanId, oneYearCorpPlan: $oneYearCorpPlan) {
      ...WeeklyTargetFields
    }
  }
`;

const SCORECARD_STEP_SUBSCRIPTION = gql`
  ${WEEKLY_TARGET_FIELDS}
  subscription OnScoreCardMutation($users: [ID!]) {
    weeklyTargetPayload(users: $users) {
      weeklyTargetMutated {
        ...WeeklyTargetFields
      }
      action
    }
  }
`;
