import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { v4 } from "uuid";
import useForm from "../../hooks/useForm";
import styles from "./AddMeetingDialog.module.scss";
import { isDate, startOfToday } from "date-fns";
import { before } from "../../utils/dates";
import { formatAs } from "../../utils/dates";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DEFAULT_MEETING_ACTIONS, REPEATABLE_MEETING_ACTIONS } from "../../utils/const";
import { getMeetingStepsFromType, getLockContentTypes} from "../../utils/misc";
import NumberFormatCustom from "../NumberFormat/NumberFormat";
import MeetingStepContentList from "../MeetingStepContent/MeetingStepContentList";

import { DatePicker } from "@material-ui/pickers";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Icon,
  Divider,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  TextareaAutosize,
  FormControl,
  Tooltip,
  DialogTitle,
  Menu,
  MenuItem,
} from "@material-ui/core";

function MeetingStepsList({ type, steps = [], onChange, error, org, planId, sharedPlanId }) {
  const [formOpen, setFormOpen] = useState(false);
  const [indexToEdit, setIndexToEdit] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [prevType, setPrevType] = useState(type);

  const handleChangeSteps = (newSteps) => {
    const sanitizedSteps = newSteps.map((step) => _.omit(step, ["dragId"])); // need to remove dragIds when passing back to the parent form
    onChange({ name: "steps", value: sanitizedSteps });
  };

  const [stepsList, setStepsList] = useState(() => {
    let stepsArr = [];
    if (_.isEmpty(steps)) {
      stepsArr = getMeetingStepsFromType(type);
    } else {
      stepsArr = steps;
    }
    stepsArr.forEach((step) => (step.dragId = v4())); // need to add dragId for duplicate/repeatable actions
    handleChangeSteps(stepsArr);
    return stepsArr;
  });

  const [customSteps, setCustomSteps] = useState(stepsList);

  const menuActionKeys = useMemo(() => {
    if (!_.isEmpty(stepsList)) {
      const usedActions = stepsList.map((action) => _.camelCase(_.get(action, "value", "")));
      const allActions = Object.keys(DEFAULT_MEETING_ACTIONS);

      const unsusedActions = _.difference(allActions, usedActions);
      return _.union(REPEATABLE_MEETING_ACTIONS, unsusedActions);
    }
    return [];
  }, [stepsList]);

  const handleOpenActionMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

  const handleRemove = (i) => {
    let newSteps = _.cloneDeep(stepsList);
    const [removedStep] = newSteps.splice(i, 1);

    setStepsList(newSteps);
    handleChangeSteps(newSteps);
  };

  const handleAddAction = (action) => () => {
    let newSteps = _.cloneDeep(stepsList);

    action.dragId = v4(); // need to add dragId for duplicate actions
    newSteps.splice(newSteps.length - 1, 0, action); // Use splice to add the element before the last item (i.e. before conclude)

    setStepsList(newSteps);
    handleChangeSteps(newSteps);
  };

  const handleEditAction = (action) => {
    let newSteps = _.cloneDeep(stepsList);
    let stepToUpdate = newSteps[indexToEdit];
    _.set(newSteps, [indexToEdit], { ...stepToUpdate, ...action });

    setStepsList(newSteps);
    handleChangeSteps(newSteps);
    setFormOpen(false);
  };

  const handleOpenEditDialog = (index) => {
    setFormOpen(true);
    setIndexToEdit(index);
  };

  const handleDragEnd = ({ draggableId, destination, source }) => {
    if (_.isEqual(source, destination)) return;

    let newSteps = _.cloneDeep(stepsList);

    const [srcItem] = newSteps.splice(source.index, 1);
    newSteps.splice(destination.index, 0, srcItem);

    setStepsList(newSteps);
    handleChangeSteps(newSteps);
  };

  const cleanseString = (str) => {
    return str.replace(/[^\w\s]|_/g, "").toLowerCase();
  };

  useEffect(() => {
    if (type !== prevType) {
      let newSteps = [];
      if (type === "custom" || type === "customNoDept") {
        // if the type is changed to custom, revert to the user customized steps
        newSteps = customSteps;
      } else {
        // if the type is changed to non-custom, grab new steps from type
        if (prevType === "custom" || prevType === "customNoDept") {
          // if the type changed from is custom, save the customized steps first
          setCustomSteps(stepsList);
        }
        newSteps = getMeetingStepsFromType(type);
        newSteps.forEach((step) => (step.dragId = v4()));
      }
      setStepsList(newSteps);
      handleChangeSteps(newSteps);

      setPrevType(type);
    }
  }, [type]);

  const nonCustom = type !== "custom" && type !== "customNoDept";
  const concludeIdx = _.findIndex(stepsList, ["value", "conclude"]);
  const concludeAction = stepsList[concludeIdx];
  const { name: concludeName, value: concludeValue, duration: concludeDuration } = concludeAction;

  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={error} className={styles.stepsContainer}>
      <div className={styles.inputLabel}>Steps</div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="SCF" type="successCriteriaForm">
          {(provided, snapshot) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {_.isEmpty(stepsList) ? (
                <ListItem className={styles.stepsListItem}>
                  <ListItemText>Please add meeting steps</ListItemText>
                </ListItem>
              ) : (
                <>
                  {stepsList.map(({ name, value, duration, dragId }, index) => {
                    if (value !== "conclude") {
                      const nameValMatch = cleanseString(value) === cleanseString(name);
                      return (
                        <Draggable key={dragId} draggableId={`ACTION_${dragId}`} index={index} isDragDisabled={nonCustom}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{ ...provided.draggableProps.style }}
                            >
                              <ListItem className={styles.stepsListItem} key={index}>
                                <ListItemText>
                                  <span>{name}</span>{" "}
                                  <span style={{ color: "#673ab7" }}>{!nameValMatch && `(${_.capitalize(value)}) `}</span>
                                  <span style={{ color: "lightgray" }}>{duration}m</span>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                  <IconButton onClick={() => handleOpenEditDialog(index)}>
                                    <Icon>edit</Icon>
                                  </IconButton>
                                  <IconButton onClick={() => handleRemove(index)} disabled={nonCustom}>
                                    <Icon>delete</Icon>
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                              <Divider />
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  })}
                  {provided.placeholder}
                  <div>
                    <ListItem className={styles.stepsListItem}>
                      <ListItemText>
                        <span>{concludeName}</span>{" "}
                        <span style={{ color: "#673ab7" }}>
                          {cleanseString(concludeName) !== cleanseString(concludeValue) && `(${_.capitalize(concludeValue)}) `}
                        </span>
                        <span style={{ color: "lightgray" }}>{concludeDuration}m</span>
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => handleOpenEditDialog(concludeIdx)}>
                          <Icon>edit</Icon>
                        </IconButton>
                        <IconButton onClick={() => handleRemove(concludeIdx)} disabled={nonCustom} style={{ visibility: "hidden" }}>
                          <Icon>delete</Icon>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </div>
                </>
              )}
              <Button
                id="add-action-button"
                variant="outlined"
                color="primary"
                style={{ width: "100%", marginTop: 8 }}
                aria-controls="add-action-menu"
                aria-haspopup="true"
                onClick={handleOpenActionMenu}
                disabled={nonCustom}
              >
                <Icon>add_circle</Icon> Add
              </Button>
              <Menu
                id="add-action-menu"
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseActionMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                {menuActionKeys.map((actionKey, idx) => {
                  const action = DEFAULT_MEETING_ACTIONS[actionKey];
                  const name = _.get(action, "name", _.capitalize(actionKey));
                  return (
                    <MenuItem onClick={handleAddAction(action)} key={idx}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Menu>
            </List>
          )}
        </Droppable>
      </DragDropContext>
      {formOpen && (
        <ActionForm
          action={_.get(stepsList, `[${indexToEdit}]`, null)}
          open={formOpen}
          onClose={() => setFormOpen(false)}
          onSubmit={handleEditAction}
          org={org}
          planId={planId}
          sharedPlanId={sharedPlanId}
        />
      )}
    </FormControl>
  );
}

const initErrorForm = {
  name: ["required"],
  duration: ["required"],
};

function ActionForm({ action, open, onClose, onSubmit, org, planId, sharedPlanId }) {
  const { form, formErrors, handleChange, handleChangeManual, validateForm } = useForm({
    initForm: action,
    initErrorForm,
  });

  const handleSubmit = () => {
    if (!validateForm()) return;
    onSubmit(form);
  };

  const handleMcToBeDeleted = () => {
    return;
  };
  
  const lockContentTypes = getLockContentTypes(form.value);
  const stepDescription = _.capitalize(form.value)
  return (
    <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onClose}>
      <DialogTitle>Edit Meeting Step {`(${stepDescription})`}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          label="Label"
          name="name"
          value={form.name}
          onChange={handleChange}
          style={{ marginTop: 8, marginBottom: 8 }}
          helperText={formErrors.name}
          error={Boolean(formErrors.name)}
        />
        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          label="Duration (minutes)"
          name="duration"
          value={form.duration}
          onChange={handleChange}
          style={{ marginTop: 8, marginBottom: 8 }}
          helperText={formErrors.duration}
          error={Boolean(formErrors.duration)}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}         
        />

        {!_.isEmpty(lockContentTypes) && (
          <MeetingStepContentList
            lockContentTypes={lockContentTypes}
            stepDescription={stepDescription}
            stepContent={form.content}
            onChange={handleChangeManual}
            handleToBeDeleted={handleMcToBeDeleted}
            org={org}
            planId={planId}
            sharedPlanId={sharedPlanId}
          />         
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MeetingStepsList;
