import React, { useMemo } from "react";
import styles from "../Meetings.module.scss";
import _ from "lodash";
import { Button, IconButton, Grid, Container, Typography, MenuItem } from "@material-ui/core";
import Menu from "../../../components/Menu/Menu";

import { HEADLINE_CATEGORIES } from "../../../utils/const";
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import { useContext } from "react";
import { DialogContext } from "../../../context/dialogContext";
import useMobileMenu from "../../../hooks/useMobileMenu";

const HeadlineStep = ({ step, canEdit, meeting }) => {
  const { dialog, setDialog } = useContext(DialogContext);
  const { isMobile } = useMobileMenu();

  const groupedHeadlines = useMemo(() => {
    const headlinesByCategory = {};

    step.content.forEach((content) => {
        if (content.type === "headlineReview") {
        content.referenceIds.forEach((referenceId) => {
            const category = referenceId.category || HEADLINE_CATEGORIES['general'].value;
            if (!headlinesByCategory[category]) {
              headlinesByCategory[category] = [];
            }

            headlinesByCategory[category].push(referenceId);
        });
        }
    });

    return headlinesByCategory;
  }, [step.content]);

  const sortedCategories = useMemo(() => {
    return _.sortBy(Object.keys(groupedHeadlines), (category) =>
      _.get(HEADLINE_CATEGORIES, `${category}.orderKey`, 42)
    );
  }, [groupedHeadlines]);


  const handleAddHeadlineDialog = (e) => {
    e.stopPropagation();
    setDialog({
      ...dialog,
      addHeadlineDialog: { open: true, meeting: meeting},
    });
  };

  /*const { user } = useContext(UserContext);
  const canEdit=isAuthed(user.user, "department facilitator");
*/

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={styles.flexSpaceBetween}>
            <div>

                <Grid item xs={12}>
                  <Typography variant="h5">
                    <span className={styles.stepLabel}>Headlines</span>
                  </Typography>
                </Grid>

            </div>

            {isMobile ? (
              <IconButton onClick={handleAddHeadlineDialog} className={styles.menuButtonColor} color="primary" disabled={!canEdit}>
                <Icon path={mdiPlus} size={0.75} color="#fff" />
              </IconButton>
            ) : (
              <Button
                startIcon={<Icon path={mdiPlus} size={1} color="#fff" />}
                className={styles.menuButtonColor}
                onClick={handleAddHeadlineDialog}
                variant="contained"
                color="primary"
                disabled={!canEdit}
              >
                New Headline
              </Button>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
        {sortedCategories.map((category) => (
        <div key={category}>
          <h3>{_.get(HEADLINE_CATEGORIES, `${category}.name`, category)}</h3>
          <ul>
            {groupedHeadlines[category].map((headline) => (
              <li key={headline.id}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                  <div style={{display: "flex", flexDirection: "row", justifyContent:"left", alignItems: "center"}}>
                    {headline.value}
                    {canEdit && (
                        <Menu size={0.75}>
                          <MenuItem onClick={()=>{}}>Remove from the Meeting</MenuItem>
                        </Menu>
                      )}
                    </div>
                    {headline.summary && <span style={{ marginLeft: '10px', fontSize: 'smaller' }}>{headline.summary}</span>}
                    {_.get(headline,'author.name',null) && 
                      <span style={{ marginLeft: '12px', fontSize: 'smaller' }}>- Highlighted By: {_.get(headline,'author.name.first','')} {_.get(headline,'author.name.last','')}
                      </span>
                    }
                  </div>
                </li>
            ))}
          </ul>
        </div>
      ))}      
        </Grid>
      </Grid>
    </Container>
  );
};

export default HeadlineStep;