import React from "react";
import styles from "../Meetings.module.scss";
import { Button, Container, IconButton, Grid, Typography } from "@material-ui/core";
import Icon from "@mdi/react";
import MeetingStepContent from "../../../components/MeetingStepContent/MeetingStepContent";
import useMobileMenu from "../../../hooks/useMobileMenu";


import { mdiPlus } from "@mdi/js";

const MeetingCheckInScreen = ({ step, canEdit, handleAddPromptStepContent }) => {
  const { isMobile } = useMobileMenu();

  return (

    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={styles.flexSpaceBetween}>
            <div>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <span className={styles.stepLabel}>Check-in</span>
                </Typography>
              </Grid>
            </div>

            {isMobile ? (
              <IconButton onClick={handleAddPromptStepContent} className={styles.menuButtonColor} color="primary" disabled={!canEdit}>
                <Icon path={mdiPlus} size={0.75} color="#fff" />
              </IconButton>
            ) : (
              <Button
                startIcon={<Icon path={mdiPlus} size={1} color="#fff" />}
                className={styles.menuButtonColor}
                onClick={handleAddPromptStepContent}
                variant="contained"
                color="primary"
                disabled={!canEdit}
              >
                Update Ice-breakers
              </Button>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <MeetingStepContent step={step} />     
        </Grid>
   
      </Grid>
    </Container>

  );
};

export default MeetingCheckInScreen;
