import { differenceInSeconds, addSeconds, format } from "date-fns";
import React, { useEffect, useState } from "react";
import styles from "./Meetings.module.scss";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";

const StepTimeIndicator = ({ stepStartTime, timeSpentInSeconds, plannedDuration }) => {
  const [timer, setTimer] = useState(timeSpentInSeconds);

  useEffect(() => {
    let interval;
    if (!_.isNil(stepStartTime)) {
      interval = setInterval(() => setTimer(differenceInSeconds(new Date(), new Date(stepStartTime)) + timeSpentInSeconds), 1000);
    }

    return () => clearInterval(interval);
  }, [stepStartTime, timeSpentInSeconds]);

  //className={progress > 90 ? styles.StepTimeIndicatorPurple : (progress > 80 ? styles.StepTimeIndicatorWarning : styles.stepTimeIndicator)}

  //timer = number of seconds since the step started
  const timeRemaining = formatTime(plannedDuration * 60 > timer ? plannedDuration * 60 - timer : 0);
  const progress = plannedDuration * 60 > timer ? (1 - (timer / (plannedDuration * 60))) * 100 : 0;

  let className = styles.stepTimeIndicator;
  let classNameContainer = styles.stepTimeIndicatorContainer;
  if (progress <= 0) {
    className = ` ${styles.stepTimeIndicatorAlert}`;
    classNameContainer = ` ${styles.stepTimeIndicatorContainerComplete}`;
  }
  else if (progress <= 10) {
    className = ` ${styles.stepTimeIndicatorAlert}`;
    classNameContainer = ` ${styles.stepTimeIndicatorContainerAlert}`;
  } 
  else if (progress <= 20) {
    className = ` ${styles.stepTimeIndicatorWarning}`;
    classNameContainer = ` ${styles.stepTimeIndicatorContainerWarning}`;    
  }

  return (
    
    <div className={classNameContainer}>
      <Tooltip title={timeRemaining}>      
        <div className={className} style={{ transform: `translateX(-${100 - progress}%)` }} />
      </Tooltip>
    </div>
  );
};

// New function to format seconds or milliseconds into hh:mm:ss
export const formatTime = (timeInSecondsOrMilliseconds, isMilliseconds = false) => {
  const totalSeconds = isMilliseconds ? Math.floor(timeInSecondsOrMilliseconds / 1000) : timeInSecondsOrMilliseconds;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const pad = (num) => String(num).padStart(2, '0');

  if (hours > 0) {
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  } else {
    return `${pad(minutes)}:${pad(seconds)}`;
  }
};

export default StepTimeIndicator;
