import React, { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import useMeeting from "./useMeeting";
import useCorpPlan from "../../hooks/useCorpPlan";
import { DialogContext } from "../../context/dialogContext";
import { Container, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Tooltip, Switch } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import styles from "./Meetings.module.scss";
import useNotes from "../../components/Notes/useNotes";

import { Allotment, setSashSize  } from "allotment";
import "allotment/dist/style.css";

import NotesEmbedded from "../../components/Notes/NotesEmbedded";
import ScorecardStep from "./Steps/ScorecardStep";
import RockStep from "./Steps/RockStep";
import TodoStep from "./Steps/TodoStep";
import ConfirmFinishMeetingDialog from "./ConfirmFinishMeetingDialog";
import HeadlineStep from "./Steps/HeadlineStep";

import UserAvatars from "../../components/UserAvatars/UserAvatars";
import Icon from "@mdi/react";
import {
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiAlertDecagram,
  mdiBullseyeArrow,
  mdiCheckCircle,
  mdiCheckboxMarked,
  mdiCrown,
  mdiHumanHandsup,
  mdiPencilBox,
  mdiPhoneHangup,
  mdiRhombus,
  mdiText,
  mdiBullhorn,
} from "@mdi/js";
import { green, grey, purple } from "@material-ui/core/colors";
import Menu from "../../components/Menu/Menu";
import MeetingTimer from "./MeetingTimer";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MeetingEndScreen from "./Steps/MeetingEndScreen";
import MeetingCheckInScreen from "./Steps/MeetingCheckInScreen";
import MeetingConcludeScreen from "./Steps/MeetingConcludeScreen";
import LobbyStep from "./Steps/LobbyStep";
import TextBlockStep from "./Steps/TextBlockStep";

import { SnackbarContext } from "../../context/snackbarContext";

import StepTimeIndicator from "./StepTimeIndicator";
const drawerWidth = 220;


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "-30px",
    height: "calc(100vh - 90px)",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    marginTop: "60px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItemIcon: {
    minWidth: "40px",
  },
  drawerBottom: {
    height: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerBottomOpen: {
    height: drawerWidth,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerBottomClose: {
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    height: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(7) + 1,
    },
  },  
}));

const Meeting = ({ user, org }) => {
  const classes = useStyles();
  const theme = useTheme();
  const params = useParams();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const { corpForSelectedYear } = useCorpPlan({ orgId: params.org, fiscalYear: org.fiscalYear });
  const { dialog, setDialog } = useContext(DialogContext);

  const handleToggleDrawerOpen = () => {
    setOpen(!open);
  };
  const {
    loading,
    inMeeting,
    canStartMeeting,
    userLoading,
    subloading,
    isAdmin,
    isTempAdmin,
    data,
    currentStepIndex,
    completedStepIndex,
    currentSessionSubscription,
    confirmOpen,
    filterOnSelected,
    stepFilters,
    currentLeader,
    handleChangeStep,
    handleResetSession,
    handleStepButton,
    handleRateMeeting,
    handleFinishMeeting,
    handleExitMeeting,
    handlePassAdminControl,
    handleRevokeAdminControl,
    handleConfirmOpen,
    handleToggleFilterOnSelected,
    handleSetStepFilters,
  } = useMeeting(user, _.get(corpForSelectedYear,"id",null));

 
  const steps = useMemo(() => _.get(data, 'meeting.steps', []), [data]);
  const currentStep = useMemo(() => _.get(steps, `[${currentStepIndex}]`, null), [steps, currentStepIndex]);

  //Do not use NotesContext directly, as a dialog based note can be opened while an embedded note is displayed.
  //For that reason, we need to replicated some noteContext logic here.
  const [notesData, setNotesData] = useState({ id: null, model: null, value: null, user: null, additionalProps: {}, planId: null });
  const [refetchId, setRefetchId] = useState(); // no longer used
  
  // additionalProps can only include variables to the query
  const notes = (id, model, core = null, openInEditMode = false, openNotesDialog = true, value = null, user = null, additionalProps = {}, planId = null) => {
    setNotesData({ id, model, core, openInEditMode, openNotesDialog, value, user, additionalProps, planId });
  };

  // for handling sub array notes
  const onCreateNote = () => {
    setNotesData({ ...notesData, additionalProps: { ...notesData.additionalProps, new: false } });
  };

  const { id, model, core, openInEditMode, openNotesDialog, value: referenceObjectValue, additionalProps, planId } = notesData;

 
  const { snack } = useContext(SnackbarContext);
/*
  const {
    data: { id, model, core, openInEditMode, openNotesDialog, user: userId, value: referenceObjectValue, additionalProps, planId },
    notes,
    setRefetchId,
    onCreateNote,
  } = useContext(NotesContext);
*/
  const {
     handleClose,
  } = useNotes({
    user,
    snack,
    notes,
    model,
    setRefetchId,
    openInEditMode,
    onCreateNote,
    openNotesDialog,
  });

  const handleNotes = useCallback(
    (id, model = "meeting", core = "minutes", openInEditMode = false) => {
      // By default, openInEditMode is true, and openNotesDialog is locked to false
      notes(id, model, core, openInEditMode, false);
    },
    [] // Add dependencies here if `notes` relies on any external values
  );
    
  
  useEffect(() => {
    const id = _.get(data, "meeting.id");
    if (id) {
      handleNotes(id, "meeting", "minutes");
    }
  }, [data, handleNotes]);

  if (loading || userLoading || subloading) return null;
  if (!data) return null;

  const handleLeaveMeeting = () => {
    handleExitMeeting();
    handleClose();
    history.push(`/${org.id}/weekly-meeting`);    
  };

  const handleFinishAndLeaveMeeting = () => {
    handleFinishMeeting();

    handleExitMeeting();
    handleClose();
    history.push(`/${org.id}/weekly-meeting`);    
  };


  const { owner, users, endTime, plan, sharedPlanId } = data.meeting;
  
  const corpPlanId = _.get(plan,'id',corpForSelectedYear.id);

  const allInvitedUsers = [...users, owner];
  const userById = _.keyBy(allInvitedUsers, "id");
 
  const userIds = Object.keys(userById);

  const attendees = (_.get(currentSessionSubscription, "meetingSub.session.users") || []).map((userId) => userById[userId]);
  const meetingUsers = _.get(data, "meeting.users") || [];
  const stepTime = _.get(currentSessionSubscription, "meetingSub.session.stepTime");

  const tempAdmin = _.get(currentSessionSubscription, "meetingSub.session.temporaryAdmin");
  const canEdit = isAdmin || isTempAdmin;

  const currentUserID = _.get(user,'user.id','no-user-id')
  const isLeading = currentLeader === currentUserID;

  const sessionEndTime = _.get(currentSessionSubscription, "meetingSub.session.sessionEndTime");
  const meetingAlreadyEnded = !_.isNil(sessionEndTime) || !_.isNil(endTime);

  const handleAddPromptStepContent= (e) => {
    e.stopPropagation();
    setDialog({
      ...dialog,
      addStepContentDialog: { open: true, contentType: 'prompt', corpPlanId, fiscalYear: org.fiscalYear, meeting: data.meeting },
    });
  };

  const handleAddTextBlockStepContent= (e) => {
    e.stopPropagation();
    setDialog({
      ...dialog,
      addStepContentDialog: { open: true, contentType: 'textBlock', corpPlanId, fiscalYear: org.fiscalYear, meeting: data.meeting },
    });
  };

  

  const stepsComponents = {
    "lobby": <LobbyStep org={org} handleResetSession= {handleResetSession} step={currentStep} invited={allInvitedUsers} attendees={attendees} canStartMeeting={canStartMeeting}/>,
    "check-in": <MeetingCheckInScreen step={currentStep} canEdit={canEdit} handleAddPromptStepContent={handleAddPromptStepContent}/>,
    "headlines": <HeadlineStep step={currentStep} canEdit={canEdit} meeting={_.get(data, "meeting",null)}/>,
    "text-block": <TextBlockStep step={currentStep} canEdit={canEdit} handleAddTextBlockStepContent={handleAddTextBlockStepContent}/>,    
    "scorecard": <ScorecardStep userIds={userIds} step={currentStep} org={org} canEdit={canEdit} corpPlanId={corpPlanId} sharedPlanId={sharedPlanId} filterOnSelected={filterOnSelected} handleSetStepFilters={handleSetStepFilters} stepFilters={stepFilters} isLeading={isLeading}/>,
    "rocks": <RockStep userIds={userIds} org={org} step={currentStep} canEdit={canEdit} corpPlanId={corpPlanId} sharedPlanId={sharedPlanId} fiscalYear={org.fiscalYear} filterOnSelected={filterOnSelected} handleSetStepFilters={handleSetStepFilters} stepFilters={stepFilters} isLeading={isLeading}/>,
    "to-dos": <TodoStep userIds={userIds} org={org} step={currentStep} category={"todo"} canEdit={canEdit} corpPlanId={corpPlanId} sharedPlanId={sharedPlanId} filterOnSelected={filterOnSelected} handleSetStepFilters={handleSetStepFilters} stepFilters={stepFilters} isLeading={isLeading}/>,
    "issues": <TodoStep userIds={userIds} org={org} step={currentStep} category={"issue"} canEdit={canEdit} corpPlanId={corpPlanId} sharedPlanId={sharedPlanId} filterOnSelected={filterOnSelected} handleSetStepFilters={handleSetStepFilters} stepFilters={stepFilters} isLeading={isLeading}/>,
    "conclude": <MeetingConcludeScreen user={_.get(user,'user',null)} canEdit={false} handleRateMeeting={handleRateMeeting} attendees={attendees} meeting={_.get(data, "meeting",null)}/>,
    "end-screen": <MeetingEndScreen org={org} canEdit={canEdit} minutes={_.get(data, "meeting.minutes",null)}/>,
  };

  const handleEditMeeting = (e) => {
    e.stopPropagation();
    setDialog({
      ...dialog,
      addMeetingDialog: { open: true, corpPlanId: _.get(corpForSelectedYear, "id"), fiscalYear: org.fiscalYear, meeting: _.get(data, "meeting") },
    });
  };

  //To Do: Need to expand this to check that no-one else in the attendees list is a department admin, because they can close the meeting too 
  //(so no need to grant a temp admin the ability to close the meeting in that case)
  const canEndMeeting = (isAdmin ) || (isTempAdmin && !attendees.includes(_.get(data, "meeting.owner.id")));
  setSashSize(20);
  return (
    <>
    <div className={classes.root}>
      <div className={styles.stepContainer}>
        <div className={styles.meetingTopBar}>
          <div className={styles.userContainer}>
            <Icon path={mdiAccountMultiple} size={1} color={grey[800]} style={{ marginRight: "4px" }} />
            {!_.isEmpty(_.get(currentSessionSubscription, "meetingSub.session.users", [])) && (
              <UserAvatars small users={attendees} hidden maxUsersVisible={5} />
            )}
          </div>
          <div className={styles.toolsContainer}>
            {(isAdmin || isTempAdmin) && (
              <Tooltip title="Display Meeting Items Only">
                <Switch
                  checked={filterOnSelected}
                  onChange={handleToggleFilterOnSelected}
                  name={`filterOnSelected`}
                  data-test='mt-switch-filter-on-selected'
                />
              </Tooltip>
              )}
            
            {isAdmin && (
              <>
                <Tooltip title="Edit Meeting">
                  <IconButton onClick={handleEditMeeting} data-test='MT-button-edit-meeting'>
                    <Icon path={mdiPencilBox} size={1}  />
                  </IconButton>     
                </Tooltip>     

                <Menu icon="crown" tooltipText="Update Meeting Control">
                  {tempAdmin && <MenuItem onClick={handleRevokeAdminControl}>Revoke Admin Control</MenuItem>}
                  {!_.isEmpty(meetingUsers) ? (
                    meetingUsers.map((user) => (
                      <MenuItem key={user.id} onClick={() => handlePassAdminControl(user.id)}>
                        {user.name.first} {user.name.last}{" "}
                        {tempAdmin === user.id && <Icon path={mdiCrown} size={0.75} color="rgba(0, 0, 0, 0.54)" />}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No users</MenuItem>
                  )}
                </Menu>
              </>
            )}
          </div>
        </div>
        <div className={styles.meetingContainer}>
          <Allotment vertical style={{
        '--separator-border': 'rgba(255, 0, 0, 0.7)', // Purple with transparency
        '--focus-border': '#00ff00', // Bright blue for focus
        '--sash-size': '36px', // 8px sash
      }}>
        <Allotment.Pane>
          <div className={styles.stepContainer}>
            <div className={styles.stepContentFlex}>
              {!_.isNil(currentStep) && stepsComponents[currentStep.value] && !meetingAlreadyEnded && (
                <Container className={styles.container} maxWidth={false}>
                  {inMeeting ? (
                    stepsComponents[currentStep.value]
                  ) : (
                    stepsComponents["lobby"]
                  )}
                </Container>
              )}
              {meetingAlreadyEnded && (
                <Container className={styles.container} maxWidth={false}>
                  {stepsComponents["end-screen"]}
                  </Container>
                )}        
            </div>
          </div>
        </Allotment.Pane>
        {(!meetingAlreadyEnded && inMeeting && (isAdmin || isTempAdmin)) &&
          <Allotment.Pane preferredSize={40} minSize={40}>
            <div className={styles.stepContainer}>
              <div className={styles.stepContentFlex}>
                <Container className={styles.meetingBottomBar} maxWidth={false}>
                  <NotesEmbedded user={user} notesData={notesData} notes={notes} onCreateNote={onCreateNote} refetchId={refetchId} setRefetchId={setRefetchId}/>
                </Container>
              </div>
            </div>
          </Allotment.Pane>
        }
      </Allotment>

    </div>
        


        
      </div>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        anchor="right"
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleToggleDrawerOpen}>{open ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
          {inMeeting && <MeetingTimer currentSessionSubscription={currentSessionSubscription} />}
        </div>
        <Divider />
        <List className={styles.menulist}>
          {inMeeting && !meetingAlreadyEnded ? (
            steps.map(({ name, value, duration }, i) => {
              const isCurrentStep = i === currentStepIndex;
              const isReviewed = completedStepIndex > i;
              const stepStartTime = _.get(stepTime, `${i}.stepStartTime`);
              const timeSpentInSeconds = _.get(stepTime, `${i}.timeSpentInSeconds`) || 0;
              // const isLastStep = i === steps.length - 1;
              return (
                <ListItem
                  key={i}
                  className={styles.stepItem}
                  button
                  disabled={subloading || !(isAdmin || isTempAdmin)}
                  onClick={handleChangeStep(i)}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <Icon
                      path={stepIcons[value]}
                      size={1}
                      color={isCurrentStep ? purple[400] : isReviewed ? green[400] : grey[800]}
                      className={styles.cardIcon}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <span className={styles.flex}>
                      {name}
                      {isReviewed && <Icon path={mdiCheckCircle} color={green[400]} size={0.75} className={styles.check} />}
                    </span>
                  </ListItemText>
                  {isCurrentStep && (
                    <StepTimeIndicator stepStartTime={stepStartTime} timeSpentInSeconds={timeSpentInSeconds} plannedDuration={duration} />
                  )}
                  {/* <ListItemSecondaryAction className={isCurrentStep ? undefined : styles.label}>{duration}m</ListItemSecondaryAction> */}
                </ListItem>
              );
            })
          ) : (
            <ListItem className={styles.stepItem} button disabled>
            </ListItem>
          )
        }
        </List>
        <Divider />
        <List disablePadding>
          <ListItem
            className={styles.labelEndMeeting}
            button
            disabled={subloading}
            onClick={meetingAlreadyEnded ? handleLeaveMeeting : handleConfirmOpen(true)}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Icon path={mdiPhoneHangup} size={1} color={"#fff"} className={styles.cardIcon} />
            </ListItemIcon>
            <ListItemText>
              <span className={styles.flex}>{meetingAlreadyEnded ? "Meeting Ended" : "End Meeting"}</span>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>


    
    </div>

    <ConfirmFinishMeetingDialog
        canEndMeeting = {canEndMeeting}
        confirmOpen={confirmOpen}
        meetingAlreadyEnded={meetingAlreadyEnded}
        handleConfirmOpen={handleConfirmOpen}
        handleFinishAndLeaveMeeting={handleFinishAndLeaveMeeting}
        handleLeaveMeeting={handleLeaveMeeting}
      />    
    </>
  );
};

export default Meeting;

const stepIcons = {
  "lobby": mdiAccountGroup,
  "check-in": mdiAccountGroup,
  headlines: mdiBullhorn,
  scorecard: mdiBullseyeArrow,
  rocks: mdiRhombus,
  issues: mdiAlertDecagram,
  "to-dos": mdiCheckboxMarked,
  "text-block": mdiText,
  conclude: mdiHumanHandsup,
};
