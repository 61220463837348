import React, { useEffect, useState } from "react";
import styles from "./AddWeeklyTargetDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import { isAuthed } from "../../utils/authorization";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Switch,
  FormControlLabel,
  Chip,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";
import SelectUsers from "../SelectUsers/SelectUsers";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import Loading from "../Loading/Loading";
import VariableDialog from "../VariableDialog/VariableDialog";
import NumberFormatCustom from "../NumberFormat/NumberFormat";
import SyntaxDialog from "../VariableDialog/SyntaxDialog";
import { WEEKLY_TARGET_FIELDS } from "../../utils/fragments";
import { GET_WEEKLY_TARGETS } from "../../utils/query";
import SelectMetric from "../SelectMetric/SelectMetric";
import PlanPill from "../PlanPill/PlanPill";

const initForm = {
  value: null,
  target: null,
  targetMin: null,
  targetMax: null,
  enableFormula: false,
  formula: null,
  formulaScope: [],
  accuracy: "eq",
  unit: null,
  user: [],
  plans: [],
};

const initErrorForm = {
  value: ["required"],
  target: [
    {
      type: "custom",
      callback: (form, value) => (form.accuracy !== "btw" && !(_.isNil(value) || _.isEmpty(value))) || form.accuracy === "btw",
      errorMessage: "This field is required",
    },
  ],
  targetMin: [
    {
      type: "custom",
      callback: (form, value) => (form.accuracy === "btw" && !(_.isNil(value) || _.isEmpty(value))) || form.accuracy !== "btw",
      errorMessage: "This field is required",
    },
  ],
  targetMax: [
    {
      type: "custom",
      callback: (form, value) => (form.accuracy === "btw" && !(_.isNil(value) || _.isEmpty(value))) || form.accuracy !== "btw",
      errorMessage: "This field is required",
    },
  ],
  formula: [
    {
      type: "custom",
      callback: (form, value) => (form.enableFormula === true && !(_.isNil(value) || _.isEmpty(value))) || form.enableFormula !== true,
      errorMessage: "This field is required",
    },
  ],
  user: ["required"],
};

const AddWeeklyTargetDialog = ({ dialog, setDialog, requestFetch, params, user, snack }) => {
  const isAdmin = isAuthed(user.user, "department facilitator");
  const { sharedPlanId } = _.get(user, "departmentFilter");
  const departmentFilterId = _.get(user, "departmentFilter.id");
  const oneYearCorpPlan = _.get(dialog, "addWeeklyTargetDialog.planId");
  const variables = _.get(dialog, "addWeeklyTargetDialog.variables");

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_USERS_AND_PLANS_WEEKLY_TARGET, {
    variables: { organization: params.org, sharedPlanId, oneYearCorpPlan },
  });
  const [createWeeklyTarget, { loading }] = useMutation(CREATE_WEEKLY_TARGET, {
    update(cache, { data: { createWeeklyTarget } }) {
      try {
        if (_.isNil(variables)) {
          throw new Error("variable cannot be null or undefined");
        }
        const existingData = cache.readQuery({
          query: GET_WEEKLY_TARGETS,
          variables,
        });

        // Check if the new weeklyTarget already exists in the cache
        // It could have been added already by a meeting step subscription
        const exists = existingData.weeklyTargets.some(
          (target) => target.id === createWeeklyTarget.weeklyTarget.id
        );

        if (!exists) {
          cache.writeQuery({
            query: GET_WEEKLY_TARGETS,
            variables,
            data: {
              weeklyTargets: [createWeeklyTarget.weeklyTarget, ...existingData.weeklyTargets],
            },
          });
        }
        
      } catch (e) {
        console.log(e);
      }
    },
  });
  
  const [variableDialogOpen, setVariableDialogOpen] = useState(false);
  const [formulaScopeIdx, setFormulaScopeIdx] = useState(null);

  const [inputSearchTerm, setInputSearchTerm] = useState("");
  // syntax dialog states
  const [syntaxDialogOpen, setSyntaxDialogOpen] = useState(false);

  const {
    form,
    formErrors,
    handleChange,
    handleChangeManual,
    handleToggleCheckBox,
    handleChangeToggleButton,
    resetForm,
    validateForm,
    valueAsFormattedNum,
    removeFormattedNum,
  } = useForm({
    initForm,
    initErrorForm,
  });

  const weeklyTargets = _.get(data, "weeklyTargets") || [];
  const weeklyTargetsById = _.keyBy(weeklyTargets, "id");

  const fs = useMediaQuery("(max-width: 600px)");

  const handleSubmit = (close) => async () => {
    if (!validateForm()) return;

    const { value, target, targetMin, targetMax, enableFormula, formula, formulaScope, unit, accuracy, plans, oneYearMetric } = form;

    const ok = await createWeeklyTarget({
      variables: {
        organization: params.org,
        user: isAdmin ? form.user : user.user.id,
        value,
        accuracy,
        target: !_.isNil(target) ? removeFormattedNum(target) : target,
        targetMin: !_.isNil(targetMin) ? removeFormattedNum(targetMin) : targetMin,
        targetMax: !_.isNil(targetMax) ? removeFormattedNum(targetMax) : targetMax,
        enableFormula,
        formula,
        formulaScope,
        unit,
        plans,
        oneYearMetric,
      },
    });

    if (ok.data.createWeeklyTarget) {
      snack(`Created "${value}" KPI`);
      resetForm({ ...initForm, plans: form.plans });
      // requestFetch();
      if (close) {
        handleClose();
      }
    }
  };

  const handleClose = () => {
    resetForm();
    setDialog({ ...dialog, addWeeklyTargetDialog: { open: false } });
  };

  useEffect(() => {
    if (dialog.addWeeklyTargetDialog.open && departmentFilterId && !queryLoading) {
      resetForm({ ...initForm, plans: [departmentFilterId] });
    } else {
      resetForm();
    }
  }, [dialog.addWeeklyTargetDialog.open, departmentFilterId, queryLoading]);

  useEffect(() => {
    if (dialog.addWeeklyTargetDialog.open) {
      refetch();
    }
  }, [fetch, dialog.addWeeklyTargetDialog.open]);

  const numOfvariables = _.get(form, "formulaScope.length");
  const filteredOneYearMetrics = _.filter(_.get(data, "metrics", []), (metric) => metric.value.includes(inputSearchTerm));
  const selectedOneYearMetric = _.get(data, "metrics", []).find((item) => item.id === form.oneYearMetric);

  return (
    <Dialog
      open={dialog.addWeeklyTargetDialog.open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.title}>
          Create New KPI
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label={`KPI Description`}
          name="value"
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          value={form.value || ""}
          onChange={handleChange}
          helperText={formErrors.value}
          error={Boolean(formErrors.value)}
        />
        <div className={styles.modelButtons}>
          <ToggleButtonGroup exclusive value={form.accuracy} onChange={handleChangeToggleButton("accuracy")}>
            <ToggleButton value="lt">
              <span className={styles.toggleText}>&#60;</span>
            </ToggleButton>
            <ToggleButton value="lte">
              <span className={styles.toggleText}>&#8804;</span>
            </ToggleButton>
            <ToggleButton value="eq">
              <span className={styles.toggleText}>=</span>
            </ToggleButton>
            <ToggleButton value="gte">
              <span className={styles.toggleText}>&#8805;</span>
            </ToggleButton>
            <ToggleButton value="gt">
              <span className={styles.toggleText}>&#62;</span>
            </ToggleButton>
            <ToggleButton value="btw">
              <span className={styles.toggleText}>a&#60;x&#60;b</span>
            </ToggleButton>
            <ToggleButton value="percent">
              <span>%</span>
            </ToggleButton>
            <ToggleButton value="delta">
              <span className={styles.toggleText}>&#916;</span>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className={styles.metricInput}>
          {form.accuracy !== "btw" ? (
            <TextField
              label="Target"
              name="target"
              fullWidth
              margin="normal"
              variant="outlined"
              value={form.target}
              onChange={handleChange}
              helperText={formErrors.target}
              error={Boolean(formErrors.target)}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          ) : (
            <>
              <TextField
                label="Min"
                name="targetMin"
                fullWidth
                margin="normal"
                variant="outlined"
                value={form.targetMin}
                onChange={handleChange}
                helperText={formErrors.targetMin}
                error={Boolean(formErrors.targetMin)}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <TextField
                label="Max"
                name="targetMax"
                fullWidth
                margin="normal"
                variant="outlined"
                value={form.targetMax}
                onChange={handleChange}
                helperText={formErrors.targetMax}
                error={Boolean(formErrors.targetMax)}
                style={{ marginLeft: "8px" }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </>
          )}
          <TextField
            label="Unit"
            name="unit"
            margin="normal"
            variant="outlined"
            value={form.unit || ""}
            onChange={handleChange}
            className={styles.unit}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControlLabel
            control={<Switch size="small" name="enableFormula" checked={!!form.enableFormula} onChange={handleToggleCheckBox} />}
            label="Enable Formula"
          />
        </div>
        {form.enableFormula && (
          <div className={styles.formulaInput}>
            <TextField
              placeholder="Formula (e.g. (v0 + 100)/v2)"
              name="formula"
              fullWidth
              margin="dense"
              variant="filled"
              value={form.formula}
              onChange={handleChange}
              helperText={formErrors.formula}
              error={Boolean(formErrors.formula)}
              InputProps={{
                endAdornment: (
                  <>
                    <Tooltip title="Add Variable">
                      <IconButton onClick={() => setVariableDialogOpen(true)}>
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Help">
                      <IconButton onClick={() => setSyntaxDialogOpen(true)}>
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ),
              }}
            />
            <div>
              {form.formulaScope.map((scope, idx) => {
                const { varType, value: scopeValue } = scope;
                const displayName = varType === "number" ? scopeValue : _.get(weeklyTargetsById, `${scopeValue}.value`, "");
                return (
                  <Chip
                    key={idx}
                    label={
                      <span>
                        <span style={{ fontWeight: "bold" }}>{`v${idx}: `}</span>
                        {`${displayName}`}
                      </span>
                    }
                    variant="outlined"
                    size="small"
                    style={{ marginRight: "4px" }}
                    onDelete={() => {
                      const newScope = form.formulaScope.filter((scope, index) => index !== idx);
                      handleChange({
                        target: {
                          name: "formulaScope",
                          value: newScope,
                        },
                      });
                    }}
                    onClick={() => {
                      setVariableDialogOpen(true);
                      setFormulaScopeIdx(idx);
                    }}
                  />
                );
              })}
            </div>
            <VariableDialog
              open={variableDialogOpen}
              handleClose={() => {
                setVariableDialogOpen(false);
                setFormulaScopeIdx(null);
              }}
              handleChangeVariable={(value) =>
                handleChange({
                  target: {
                    name: `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`,
                    value,
                  },
                })
              }
              formulaScope={_.get(form, `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`, {
                varType: "reference",
                value: null,
              })}
              listName={"KPI"}
              list={weeklyTargets}
              createMode={_.isNil(formulaScopeIdx)}
            />
            <SyntaxDialog open={syntaxDialogOpen} handleClose={() => setSyntaxDialogOpen(false)} />
          </div>
        )}
        {!loading && (
          <Autocomplete
            options={filteredOneYearMetrics}
            value={selectedOneYearMetric || inputSearchTerm}
            getOptionLabel={(option) => (typeof option === "string" ? option || "" : option.value || "")}
            renderOption={(option, state) => {
              const { id, value, plan } = option;
              return (
                <div className={styles.referenceItem}>
                  <PlanPill plan={plan} key={id} />
                  <Typography className={styles.referenceItemText}>{value}</Typography>
                </div>
              );
            }}
            fullWidth
            onChange={(e, metric) => handleChangeManual({ name: "oneYearMetric", value: _.get(metric, "id", null) })}
            onInputChange={(e, newInputValue) => setInputSearchTerm(newInputValue)}
            renderInput={(params) => <TextField {...params} style={{ marginTop: "16px" }} variant="outlined" label={`One Year Metric`} />}
          />
        )}
        {/* <SelectMetric
          showAll={true}
          metrics={_.get(data, "metrics")}
          label="One Year Metric"
          name="oneYearMetric"
          handleChange={handleChange}
          value={form.oneYearMetric}
          helperText={formErrors.oneYearMetric}
          error={Boolean(formErrors.oneYearMetric)}
        /> */}
        <SelectDepartment
          plans={_.get(data, "plans")}
          name="plans"
          handleChange={handleChange}
          value={form.plans}
          error={Boolean(formErrors.plans)}
          multiple
          disabled={!_.isNil(departmentFilterId)}
        />
        {isAdmin && (
          <SelectUsers
            name="user"
            users={_.get(data, "users")}
            handleChange={handleChange}
            values={form.user}
            helperText={`Who is accountable for this KPI?`}
            plan={form.plans}
            allPlans={_.get(data, "plans")}
            error={Boolean(formErrors.user)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(false)} color="primary" variant="outlined" disabled={loading} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Create & Add Another"}
        </Button>
        <Button onClick={handleSubmit(true)} color="primary" variant="contained" disabled={loading} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWeeklyTargetDialog;

const GET_USERS_AND_PLANS_WEEKLY_TARGET = gql`
  query AddKpiDialog_GetUsersPlansKpis($organization: ID!, $sharedPlanId: ID, $oneYearCorpPlan: ID) {
    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: "1 year", oneYearCorpPlan: $oneYearCorpPlan) {
      id
      departmentName
      sharedPlanId
    }

    weeklyTargets(organization: $organization, sharedPlanId: $sharedPlanId, oneYearCorpPlan: $oneYearCorpPlan) {
      id: _id
      value
      enableFormula
      measurables {
        week
        value
        notes {
          id
        }
      }
    }

    metrics(organization: $organization, category: "quarter", corpPlan: $oneYearCorpPlan) {
      id
      value
      plan {
        id
        color
        shortName
        departmentName
        sharedPlanId
      }
    }
  }
`;

const CREATE_WEEKLY_TARGET = gql`
  ${WEEKLY_TARGET_FIELDS}
  mutation AddKpiDialog_CreateKpi(
    $organization: ID!
    $value: String!
    $user: ID!
    $target: String
    $targetMin: String
    $targetMax: String
    $enableFormula: Boolean
    $formula: String
    $formulaScope: [FormulaVariableInput!]
    $accuracy: String!
    $unit: String
    $plans: [ID!]
    $oneYearMetric: ID
  ) {
    createWeeklyTarget(
      organization: $organization
      value: $value
      user: $user
      target: $target
      targetMin: $targetMin
      targetMax: $targetMax
      enableFormula: $enableFormula
      formula: $formula
      formulaScope: $formulaScope
      accuracy: $accuracy
      unit: $unit
      plans: $plans
      oneYearMetric: $oneYearMetric
    ) {
      weeklyTarget {
        ...WeeklyTargetFields
      }
      metrics {
        id
        weeklyTarget
      }
      weeklyTargets {
        id: _id
        oneYearMetric
      }
    }
  }
`;
