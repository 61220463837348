import React, { useEffect, useRef, useState } from "react";
import styles from "./MeetingStepContent.module.scss";
import { isAuthed } from "../../utils/authorization";
import _ from "lodash";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Autocomplete} from "@material-ui/lab";
import gql from "graphql-tag";
import { getReferenceModelFromType } from "../../utils/misc";

import { USER_FIELDS } from "../../utils/fragments";

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Divider,
  Icon,
  IconButton,
  TextField,
  Typography,
  FormControl,
  Tooltip,
} from "@material-ui/core";


import PlanPill from "../PlanPill/PlanPill";
import { getPlansByReferenceModel } from "../../utils/misc";

import UserAvatars from "../UserAvatars/UserAvatars";
import { withStyles } from "@material-ui/styles";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

const SelectStepContentItems = ( { type, referenceIds = [], stepDescription, handleChange, error, org, planId, sharedPlanId, multiple = true }) => {
  
  const [menuItems, setMenuItems] = useState([]);
 

  const getDescriptionFromReferenceModel = (referenceModel) => {
  
    let contentDescription = '';
    switch (referenceModel) {
      case 'SystemPrompt':
        contentDescription = 'Questions or Prompts';
        break;
      case null:
        contentDescription = 'Descriptive Text';
        break;
      case 'Rock':
        contentDescription = 'Rocks';
        break;
      case 'WeeklyTarget':
        contentDescription = 'KPIs';
        break;
      case 'Todo':
        contentDescription = 'To Dos';
        break;
      case 'Issue':
        contentDescription = 'Issues';
        break;
      case 'Metric':
        contentDescription = 'Metrics';
        break;
      case 'SuccessCriteria':
        contentDescription = 'Success Criteria';
        break;
      case 'Objective':
        contentDescription = 'Objectives';
        break;
      default:
        contentDescription = '';
    }    
    return contentDescription;
  }

  const referenceModel = getReferenceModelFromType(type);

  const handleChangeReferenceId = (item) => {
    let newReferenceIds = [];

    setInputValue(item);

    if (multiple) {
      //check to see if the new item is already in the list, if not, add it
      if (item && !referenceIds.some((refObject) => refObject.id === item.id)) {
        newReferenceIds = _.cloneDeep(referenceIds);        newReferenceIds.push(item);
        handleChange(newReferenceIds);
      }
    }
    else {
      newReferenceIds.push(item);
      handleChange(newReferenceIds);
    }

    setInputSearchTerm('');
    
    return;
  };

  const handleRemove = (i) => {
    let newReferenceIds = _.cloneDeep(referenceIds);
    const [removedItem] = newReferenceIds.splice(i, 1);
    handleChange(newReferenceIds);

    return;
  };
    
  useEffect(() => {
    if (type) {
        setInputSearchTerm('');
    }
  }, [type]);  

  const isTodoReference = ["Todo", "Issue"].includes(referenceModel);

  // for fetching referenced todos that are not loaded yet
  const [getReferencedTodo, { data: referencedTodosData, loading: referenceLoading }] = useLazyQuery(GET_REFERENCED_TODOS);
  const [searchTerm, setSearchTerm] = useState("");
  const [inputSearchTerm, setInputSearchTerm] = useState("");
  const [inputValue, setInputValue] = useState(null);

  const debouncedSetSearchTermRef = useRef(_.debounce((newValue) => setSearchTerm(newValue), 700));
  useEffect(() => {
    if (debouncedSetSearchTermRef.current) {
      debouncedSetSearchTermRef.current.cancel();
      debouncedSetSearchTermRef.current(inputSearchTerm);
    }
  }, [inputSearchTerm]);

  const {
    data,
    refetch,
    loading: contentItemDialogLoading,
  } = useQuery(GET_SC_ROCKS_OBJECTIVES_METRICS, {
    variables: { organization: org, searchTerm, sharedPlanId: sharedPlanId, corpPlan: planId },
  });

  const {
    data: systemPromptData,
    refetch: systemPromptDataRefetch,
    loading: systemPromptDataLoading,
  } = useQuery(GET_SYSTEMPROMPTS, {
    variables: { organization: org, searchTerm },
  });

  const {
    data: todosData,
    loading: todosLoading,
    fetchMore: fetchMoreTodos,
  } = useQuery(GET_MORE_TODOS, {
    variables: { organization: org, sharedPlanId, limit: 25, cursor: null, searchTerm, oneYearCorpPlan: planId },
  });

  const {
    data: issueData,
    loading: issuesLoading,
    fetchMore: fetchMoreIssues,
  } = useQuery(GET_MORE_ISSUES, {
    variables: { organization: org, sharedPlanId, limit: 25, cursor: null, searchTerm, oneYearCorpPlan: planId },
  });

  const loadMoreItems = (event) => {
    const userScrolledToTheBottom = Math.abs(event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop) < 1;
    if (isTodoReference && userScrolledToTheBottom) {
      //user is at the end of the list so load more items
      if (referenceModel === "Todo" && !_.isNil(_.get(todosData, "results.nextCursor"))) {
        fetchMoreTodos({
          variables: {
            organization: org,
            sharedPlanId,
            limit: 25,
            cursor: todosData.results.nextCursor,
            currentReferenceId: null,
            searchTerm,
          },
        });
      }

      if (referenceModel === "Issue" && !_.isNil(_.get(issueData, "results.nextCursor"))) {
        fetchMoreIssues({
          variables: {
            organization: org,
            sharedPlanId,
            limit: 25,
            cursor: issueData.results.nextCursor,
            currentReferenceId: null,
            searchTerm,
          },
        });
      }
    }
  };

  const createContentListItem = (item,index) => {

    let value = '';
    let user = null;
    let plans = null;
    let done = false

    switch (type) {
      case "prompt":
        value = _.get(item, 'value',"Empty Prompt/Question");
        break;
        case "issueReview":
        case "todoReview":
          value = _.get(item, 'value',"");
          user = _.get(item, 'user',null);
          plans = _.get(item, 'plan',null);
          done = _.get(item, 'done',false);
          break;    
        case "rockReview":
          value = _.get(item, 'value',"");
          user = _.get(item, 'users',null);
          plans = _.get(item, 'plan',null);
          done = _.get(item, 'status',false) === 'complete';
          break;
        case "weeklyTargetReview":
          value = _.get(item, 'value',"");
          user = _.get(item, 'users',null);

          const allplans = getPlansByReferenceModel(getReferenceModelFromType(type), item);
          const uniqPlans = _.uniqBy(allplans, (plan) => plan.sharedPlanId);
          plans = !_.isEmpty(uniqPlans) ? uniqPlans : null;

          break;          
      default:
        break;
    }

    const contentLabel = <div>
      <ListItem className={styles.msListItem}>
        {user &&
          <ListItemAvatar>
            <UserAvatars users={Array.isArray(user) ? user : (user ? [user] : [])} />
          </ListItemAvatar>
        }
        <ListItemText style={{ maxWidth: "80%" }}>
          <div className={styles.flex}>
            {Array.isArray(plans) ? (
              plans.map((plan, idx) => <PlanPill plan={plan} key={idx} />)
            ) : (
              !_.isNil(plans?.departmentName) ? <PlanPill plan={plans} /> : (referenceModel !== 'SystemPrompt') && <PlanPill plan={null} />
            )}
            <LightTooltip title={value} interactive placement="left">
              <div className={done ? styles.completed : undefined} style={{ maxHeight: "35px", overflow: "hidden" }}>
                {value}
              </div>
            </LightTooltip>
          </div>
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton onClick={() => handleRemove(index)}>
            <Icon>delete</Icon>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </div>;

    return contentLabel;
  }
    
  useEffect(() => {
    const referenceModel = getReferenceModelFromType(type);
    switch (referenceModel) {
      case "SystemPrompt":
        setMenuItems(_.get(systemPromptData, "systemPrompts", []));
        break;

      case "SuccessCriteria":
        setMenuItems(_.get(data, "successCriterias", []));
        break;
      case "Rock":
        setMenuItems(_.get(data, "rocks", []));
        break;

      case "Objective":
        setMenuItems(_.get(data, "objectives", []));
        break;

      case "Metric":
        const metrics = _.get(data, "metrics", []);
        const uniqueMetrics = [];
        metrics.forEach((metric) => {
          if ( (metric.status !== "complete") && (!_.find(uniqueMetrics, (m) => m.value === metric.value))) {
            uniqueMetrics.push(metric);
          }
        });
        setMenuItems(uniqueMetrics);
        break;

      case "WeeklyTarget":
        setMenuItems(_.get(data, "weeklyTargets", []));
        break;

      case "Issue":
        setMenuItems(_.get(issueData, "results.todos", []));
        break;

      case "Todo":
        setMenuItems(_.get(todosData, "results.todos", []));
        break;
      default:
        setMenuItems([]);
        break;
    }
  }, [type, contentItemDialogLoading, systemPromptDataLoading, issueData, todosData, systemPromptData, data]);

 useEffect(() => {
    if (isTodoReference) {
      getReferencedTodo({
        variables: {
          ids: referenceIds,
        },
      });
    }
  }, [isTodoReference]);

  const combinedMenuItems = menuItems.filter((item) => !referenceIds.some((refObject) => refObject.id === item.id));

  const selectedItem = null;

  return (
    <FormControl fullWidth variant="outlined" margin="none">
    
        {referenceModel && (
          <>
            <Autocomplete
              options={combinedMenuItems}
              value={selectedItem}
              getOptionLabel={(option) => (typeof option === "string" ? option || "" : option.value || "")}
              inputValue={inputSearchTerm}
              renderOption={(option, state) => {
                const { id, value } = option;
                const plans = getPlansByReferenceModel(referenceModel, option);
                const uniqPlans = _.uniqBy(plans, (plan) => plan.sharedPlanId);
                return (
                  <div className={styles.tieInItem}>
                    {uniqPlans.map((plan, idx) => (
                      <PlanPill plan={plan} key={idx} />
                    ))}
                    {(referenceModel !== 'SystemPrompt' && _.isEmpty(uniqPlans)) && <PlanPill plan={null} />}
                    <Typography className={styles.tieInItemText}>{value}</Typography>
                  </div>
                );
              }}
              ListboxProps={{
                onScroll: (e) => loadMoreItems(e),
              }}
              fullWidth
              onChange={(e, newValue) => handleChangeReferenceId(newValue)}
              onInputChange={(e, newInputValue) => setInputSearchTerm(newInputValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ marginTop: "16px" }}
                  variant="outlined"
                  label={`Select ${getDescriptionFromReferenceModel(referenceModel)}`}
                />
              )}
            />          


            <FormControl fullWidth variant="outlined" margin="normal" error={error} className={styles.msContainer}>
              <div className={styles.inputLabel}>{`${stepDescription}`} Items</div>
              <List>
              {_.isEmpty(referenceIds) ? (
                <ListItem className={styles.msListItem}>
                  <ListItemText>No step content set at the moment</ListItemText>
                </ListItem>
              ) : (
                referenceIds.map((contentItem, index) => {
                  return createContentListItem(contentItem, index)
                })
              )}
            </List>
            </FormControl>
          </>
        )}

    </FormControl>
  );
};

export default SelectStepContentItems;

const GET_SYSTEMPROMPTS = gql`
  ${USER_FIELDS}

  query ContentItemDialog_GetSystemPrompts($organization: ID, $searchTerm: String) {
    systemPrompts(organization: $organization, searchTerm: $searchTerm) {
      id
      organization
      category
      value
      summary
      author {
        ...UserFields
      }
    }
  }
`;

const GET_SC_ROCKS_OBJECTIVES_METRICS = gql`
  query ContentItemDialog_GetScRocksObjsMetrics($organization: ID!, $sharedPlanId: ID, $searchTerm: String, $corpPlan: ID) {
    successCriterias(organization: $organization, searchTerm: $searchTerm, oneYearCorpPlan: $corpPlan) {
      id
      value
      rock {
        id
        plan {
          id
          departmentName
          color
          shortName
          sharedPlanId
        }
      }
    }

    rocks(organization: $organization, sharedPlanId: $sharedPlanId, searchTerm: $searchTerm, oneYearCorpPlan: $corpPlan) {
      id
      value
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }

    objectives(
      organization: $organization
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      corpPlan: $corpPlan
      includeThreeYear: true
    ) {
      id
      value
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }

    metrics(
      organization: $organization
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      corpPlan: $corpPlan
      includeThreeYear: true
    ) {
      id
      value
      status
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }

    weeklyTargets(organization: $organization, sharedPlanId: $sharedPlanId, searchTerm: $searchTerm, oneYearCorpPlan: $corpPlan) {
      id: _id
      value
      plans {
        shortName
        color
        departmentName
        sharedPlanId
      }
    }
  }
`;

const GET_MORE_TODOS = gql`
  query ContentItemDialog_GetMoreTodos(
    $organization: ID!
    $sharedPlanId: ID
    $cursor: String
    $limit: Int
    $searchTerm: String
    $oneYearCorpPlan: ID
  ) {
    results: moreTodos(
      organization: $organization
      category: "todo"
      cursor: $cursor
      limit: $limit
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      oneYearCorpPlan: $oneYearCorpPlan
    ) {
      todos {
        id: _id
        value
        plan {
          id
          shortName
          color
          departmentName
          sharedPlanId
        }
      }
      nextCursor
    }
  }
`;

const GET_MORE_ISSUES = gql`
  query ContentItemDialog_GetMoreIssues(
    $organization: ID!
    $sharedPlanId: ID
    $cursor: String
    $limit: Int
    $searchTerm: String
    $oneYearCorpPlan: ID
  ) {
    results: moreTodos(
      organization: $organization
      category: "issue"
      cursor: $cursor
      limit: $limit
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      oneYearCorpPlan: $oneYearCorpPlan
    ) {
      todos {
        id: _id
        value
        plan {
          id
          shortName
          color
          departmentName
          sharedPlanId
        }
      }
      nextCursor
    }
  }
`;

const GET_REFERENCED_TODOS = gql`
  query ContentItemDialog_GetRefTodo($ids: ID) {
    todos(ids: $id) {
      id: _id
      value
      plan {
        id
        shortName
        color
        departmentName
        sharedPlanId
      }
    }
  }
`;
