import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useContext } from "react";
import { ROCK_FIELDS } from "../../../utils/fragments";
import { getCurrentQuarter } from "../../../utils/dates";
import Loading from "../../../components/Loading/Loading";

import _ from "lodash";
import Rocks from "../../../components/Rocks/Rocks";
import { FetchContext } from "../../../context/fetchContext";

const RockStep = ({ step, userIds, org, canEdit, corpPlanId, sharedPlanId, fiscalYear, filterOnSelected, handleSetStepFilters, stepFilters, isLeading }) => {
  const { fetch } = useContext(FetchContext);

  const variables = { users: userIds, organization: org.id, sharedPlanId: sharedPlanId, oneYearCorpPlan: corpPlanId};

  const { subscribeToMore, data, refetch, loading } = useQuery(ROCK_STEP_QUERY, { variables });

  useEffect(() => {
    refetch();
  }, [fetch]);

  const location = 'rocks';
  const storageValue = JSON.parse(localStorage.getItem(`${location}.selectedQuarter`));
  const selectedQuarter = !_.isNil(storageValue) ? storageValue : getCurrentQuarter(fiscalYear);

  const initialFilters =  {
    sortValue: localStorage.getItem(`${location}.sortValue`) || "quarter",
    sortOrder: localStorage.getItem(`${location}.sortOrder`) || "desc",
    showAllSc: JSON.parse(localStorage.getItem(`${location}.showAllSc`)) || false,
    showHiddenRocks: JSON.parse(localStorage.getItem(`${location}.showHiddenRocks`)) || false,
    selectedQuarter: selectedQuarter,
    searchTerm: '',
  };

  const content = _.get(step, ["content",0,"referenceIds"], []).map((refId) => refId.id);
  const rocks = filterOnSelected ? _.get(data,"rocks",[]).filter((rocks) => content.includes(rocks.id)) : _.get(data,"rocks",[]);

  useEffect(
    () => {
      if (loading) {
        return;
      }

      const unsubscribe = subscribeToMore({
        document: ROCK_STEP_SUBSCRIPTION,
        variables: { users: userIds },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const rock = subscriptionData.data.rockPayload.rockMutated;
          const action = subscriptionData.data.rockPayload.action;

          // Initialize prev.rocks if it's null, undefined, or empty
          const currentRocks = prev?.rocks || [];

          let newRocks;
          switch (action) {
            case "update":
              newRocks = currentRocks.map((r) => (r.id === rock.id ? rock : r));
              break;
            case "delete":
              newRocks = currentRocks.filter((r) => r.id !== rock.id);
              break;
            case "create":
              const exists = currentRocks.some((t) => t.id === rock.id);
              newRocks = exists ? currentRocks : [...currentRocks, rock];              
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            rocks: newRocks,
          });
        },
      });
      return () => {
        unsubscribe();
      };
    },
    [loading]
  );



  useEffect(() => {
    if (isLeading && _.isNil(stepFilters)) {
        handleSetStepFilters(initialFilters);
    }
  }, [stepFilters]);

  if (loading) return <Loading />;

  return (
    <div>
      <Rocks rocks={rocks} fiscalYear={org.fiscalYear} planId={corpPlanId} variables={variables} canEdit={canEdit} handleSetStepFilters={handleSetStepFilters} stepFilters={stepFilters} isLeading={isLeading} />
    </div>
  );
};

export default RockStep;

const ROCK_STEP_QUERY = gql`
  ${ROCK_FIELDS}
  query RockStepQuery($organization: ID!, $users: [ID!], $sharedPlanId: ID, $oneYearCorpPlan: ID) {
    rocks(organization: $organization, users: $users, sharedPlanId: $sharedPlanId, oneYearCorpPlan: $oneYearCorpPlan) {
      ...RockFields
    }
  }
`;

const ROCK_STEP_SUBSCRIPTION = gql`
  ${ROCK_FIELDS}
  subscription OnRockMutation($users: [ID!]) {
    rockPayload(users: $users) {
      rockMutated {
        ...RockFields
      }
      action
    }
  }
`;
