import React, { useEffect, useRef } from "react";
import DOMPurify from 'dompurify';

import styles from "../Meetings.module.scss";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const MeetingEndScreen = ({ org, minutes }) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(`/${org.id}/weekly-meeting`);
  };

  const iframeRef = useRef(null);

  const sanitizedMinutes = DOMPurify.sanitize(minutes, {
    ALLOWED_TAGS: ['style'],
  });

  useEffect(() => {
    if (iframeRef.current) {
      const doc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      doc.open();
      doc.write(minutes);
      doc.close();
    }
  }, [minutes]);

  return (
    <>
      <div className={styles.notificationBanner}>
        <p style={{ fontSize: 24, fontWeight: "bold" }}>The meeting has ended.</p>
        <p>
          <Button variant="outlined" color="primary" onClick={handleClick}>
            Return to Select Meeting Page
          </Button>
        </p>
      </div>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <iframe ref={iframeRef} title="Meeting Minutes" style={{ flexGrow: 1, border: 'none' }} />
      </div>    
    </>
  );
};

export default MeetingEndScreen;
