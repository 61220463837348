import _ from "lodash";
import React, { createContext, useState } from "react";

export const MeetingContext = createContext();

export const MeetingContextProvider = ({ children }) => {
  const [data, setData] = useState({ meeting: null, filterOnSelected: false });

  const activeMeeting = (meeting, filterOnSelected = false) => {
    setData({ meeting, filterOnSelected });
  };

  return (
    <MeetingContext.Provider
      value={{ data, activeMeeting }}
    >
      {children}
    </MeetingContext.Provider>
  );
};
