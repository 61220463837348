import React, { useMemo } from "react";
import styles from "../Meetings.module.scss";
import { Card, CardContent, Table, TableHead, TableRow, TableCell, TableBody,  Button, IconButton, Grid, Container, Typography, Hidden } from "@material-ui/core";
import _ from "lodash";

import CardTitle from "../../../components/CardTitle/CardTitle";
import UserRating from "./UserRating";




const MeetingConcludeScreen = ({ user: currentUser, attendees, canEdit, meeting, handleRateMeeting }) => {

  const attendeesCanEdit = true;

  const handleSubmit = (values) => {
    const { userId, value, comment } = values;
    handleRateMeeting(userId, value, comment);
  };

  const userRatings = useMemo(() => {
      const ratingsMap = new Map(_.get(meeting,'ratings',[]).map(rating => [rating.user.id, rating]));
      const userRatingsArray = [];
  
      attendees.forEach(attendee => {
        if (!ratingsMap.has(attendee.id)) {
          ratingsMap.set(attendee.id, { user: attendee, value: "", comment: "" });
        }
      });
  
      ratingsMap.forEach((rating, userId) => {
        if (userId === currentUser.id) {
          userRatingsArray.unshift(rating);
        } else {
          userRatingsArray.push(rating);
        }
      });
  
      return userRatingsArray;
    }, [meeting.rating, attendees, currentUser.id]);
    
    const averageRating = useMemo(() => {
      const validRatings = userRatings.filter(rating => rating.value !== "");
      const totalRating = validRatings.reduce((sum, rating) => sum + parseFloat(rating.value), 0);
      return validRatings.length > 0 ? (totalRating / validRatings.length).toFixed(2) : "N/A";
    }, [userRatings]);

  const getColGroup = () => {
    return (
      <colgroup>
        <col style={{ width: "10%", minWidth: 140 }} />
        <col style={{ width: "23%", minWidth: 150 }} />
        <col style={{ width: "7%", minWidth: 30 }} />
        <col style={{ width: "60%", minWidth: 120 }} />
      </colgroup>
    );
  };

  return (
   <>
     <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={styles.flexSpaceBetween}>
              <div>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    <span className={styles.stepLabel}>Meeting Conclusion</span>
                 </Typography>
                  <Typography variant="h6">
                    <span className={styles.stepLabel}>Current Rating: {averageRating}</span>
                  </Typography>     
                </Grid>
             </div>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Grid item xs={12}>
        <Card className={styles.card}>
          <CardTitle vertical color="teal">
            <Typography variant="h5" className={styles.title}>
              Ratings
            </Typography>
          </CardTitle>
          <CardContent className={styles.cardContent}>
            <Table size="small" className={styles.table}>
              {getColGroup()}
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Rating</TableCell>
                  <TableCell>Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userRatings.map(rating => {
                    return (
                        <UserRating
                        key={rating.user.id}
                        user={rating.user}
                        value={rating.value}
                        comment={rating.comment}
                        canEdit={canEdit || (currentUser.id === rating.user.id && attendeesCanEdit)}
                        handleSubmit={handleSubmit}
                    />
                    );
                  }
                )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </>

  );
};

export default MeetingConcludeScreen;
